import { useState, useEffect } from 'react'
import styled from 'styled-components'
// import { List, Calendar, Activity } from 'react-feather'
import { Checkbox, Avatar, Dropdown, Button, Table, Popover } from 'antd'
import { Form, Select, Input, Popconfirm, Modal, Radio, List } from 'antd'
import { DashboardAPI } from '../../../helpers/API-consts'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../../firebaseConfig'
import { onSnapshot, collection, doc } from 'firebase/firestore'
import { EllipsisOutlined } from '@ant-design/icons'

const EditSprintForm = ({ open, onCreate, onCancel, goals }) => {
  // const [milestones, setMilestones] = useState(
  //   goals?.map((goal) => goal.milestones),
  // )
  const [connectedMilestones, setConnectedMilestones] = useState(null)
  const [tasks, setTasks] = useState([])
  const [additionalTask, setAdditionalTask] = useState(null)
  const [showAdditionalInput, setShowAdditionalInput] = useState(false)
  const [checkedTasks, setCheckedTasks] = useState(null)

  const [form] = Form.useForm()

  const renderMilestones = () => {
    let allMilestones = []
    goals?.forEach((goal) => {
      allMilestones = [...allMilestones, ...goal.milestones]
    })
    return allMilestones?.map((milestone) => {
      return (
        <Radio value={milestone.description}>{milestone.description}</Radio>
      )
    })
  }

  const renderCheckBoxes = () => {
    console.log('rendering checkboxes', connectedMilestones)
    let options = connectedMilestones?.map((milestone) => {
      let allMilestones = []
      //find the connected milestons in the milestones array
      goals?.forEach((goal) => {
        allMilestones = [...allMilestones, ...goal.milestones]
      })
      console.log('checkbox group', allMilestones)
      let connectedMilestone = allMilestones?.find((milestoneObj, index) => {
        console.log('searching for milestone', milestone, 'in', milestoneObj)
        return milestoneObj.description == milestone
      })

      if (connectedMilestone) {
        // filter connectedMilestone.tasks to only show open tasks

        return connectedMilestone.tasks
          .filter((task) => {
            return task.open
          })
          .map((task) => {
            return {
              label: task.description,
              value: { task, connectedMilestone },
            }
          })

        // return connectedMilestone.tasks.map((task) => {
        //   if (task.open) {
        //     return {
        //       label: task.description,
        //       value: { task, connectedMilestone },
        //     }
        //   }
        // })
      }
    })

    console.log(options.flat())

    return (
      <Checkbox.Group
        options={options.flat()}
        // onChange={(checkedValues) => {
        //   let _tasks = [...checkedValues]
        //   setCheckedTasks(_tasks)
        // }}
      />
    )
  }

  return (
    <Modal
      open={open}
      title={`Set up a new sprint`}
      okText="Create"
      cancelText="Cancel"
      onCancel={() => {
        setShowAdditionalInput(false)
        setAdditionalTask(null)
        onCancel()
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields()
            onCreate(values, tasks)
          })
          .catch((info) => {
            console.log('Validate Failed:', info)
          })
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: 'public',
        }}
      >
        <Form.Item
          name="select-milestone"
          label="Select the milestones you want to focus on"
          rules={[
            {
              required: true,
              message: 'Please choose milestones for this sprint!',
            },
          ]}
        >
          <Select
            mode={'multiple'}
            placeholder="Select this sprint's milestones"
            onChange={(value) => setConnectedMilestones(value)}
          >
            {goals?.map((goal) => {
              let milestones = goal.milestones
              return milestones.map((milestone) => {
                return (
                  <Select.Option value={milestone.description}>
                    {milestone.description}
                  </Select.Option>
                )
              })
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="task-group"
          label={
            connectedMilestones
              ? "Select the tasks you'd like to add from your milestones"
              : 'Choose a milestone to pull tasks from'
          }
        >
          {connectedMilestones && renderCheckBoxes()}
        </Form.Item>
        {/* {connectedMilestones && (
          <Form.Item name="additional-tasks">
            <Button
              onClick={() => {
                if (
                  window.confirm(
                    "Adding new tasks that aren't connected to a milestone can be dangerous (to your productivity) ⚠️ You sure you want to continue?",
                  )
                ) {
                  setShowAdditionalInput(true)
                } else {
                  setShowAdditionalInput(false)
                }
              }}
            >
              Add a new task
            </Button>
            {showAdditionalInput && (
              <>
                <Form.Item name="additional-tasks" label="Add a new task">
                  <Input
                    placeholder="My unexpected but super important task"
                    onChange={(e) => setAdditionalTask(e.target.value)}
                  />
                </Form.Item>
                <Form.Item name="radio-group" label="Connect to a milestone">
                  <Radio.Group>{renderMilestones()}</Radio.Group>
                </Form.Item>
              </>
            )}
          </Form.Item>
        )} */}
      </Form>
    </Modal>
  )
}

const SprintForm = ({ open, onCreate, onCancel, tasks }) => {
  // show a list of all sprint tasks with a checkbox to mark them as complete

  const handleTaskComplete = (e) => {
    console.log(`checked = ${e.target.checked}`)
  }

  return (
    <Modal
      open={open}
      title={`Sprint`}
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        onCreate()
      }}
    >
      <List
        itemLayout="horizontal"
        dataSource={tasks}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta
              avatar={item.owner ? <Avatar>{item.owner[0]}</Avatar> : null}
              title={item.description}
              // description="Ant Design, a design language for background applications, is refined by Ant UED Team"
            />

            <Checkbox onChange={handleTaskComplete} checked={item.open} />
          </List.Item>
        )}
      />
    </Modal>
  )
}

const Overview = (props) => {
  const [isMobile, setIsMobile] = useState(false)
  const [openEditSprintForm, setOpenEditSprintForm] = useState(false)
  const [showSprintForm, setShowSprintForm] = useState(false)
  const [sprintForm, setSprintForm] = useState(null)
  const [sprintTasks, setSprintTasks] = useState(null)
  const [user, loading, error] = useAuthState(auth)
  const [taskForAction, setTaskForAction] = useState(null)

  useEffect(() => {
    // map through all goals and find the tasks with inSprint marked as true
    console.log('received new goals')

    let sprintTasks = []
    props.goals.forEach((goal) => {
      goal.milestones.forEach((milestone) => {
        milestone.tasks.forEach((task) => {
          if (task.inSprint) {
            sprintTasks.push({ task, milestone })
          }
        })
      })
    })

    setSprintTasks(sprintTasks)
    console.log('sprint tasks should have updated')
  }, [props.goals])

  const updateGoals = async (newGoals) => {
    props.handleLoading()
    console.log('updating goals for dashboard', props.dashboardId)
    try {
      let response = await fetch(DashboardAPI + 'updateDashboard', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          dashboardId: props.dashboardId,
          goals: newGoals,
        }),
      })
      if (response.status === 200) console.log('updated goals in firebase')
    } catch (err) {
      console.log(err)
    }
  }

  const handleCreateSprint = async (values, tasks) => {
    console.log('values are: ', values)
    // find the corresponding tasks from values['task-group'] in the goals array

    let selectedTasks = []
    let newGoals = [...props.goals]

    values['task-group'].forEach((data) => {
      //find the task in the goals array
      let goalIndex = newGoals.findIndex((goal) => {
        return goal.milestones.includes(data.connectedMilestone)
      })

      let milestoneIndex = newGoals[goalIndex].milestones.findIndex(
        (milestone) => {
          return milestone.tasks.includes(data.task)
        },
      )

      if (goalIndex > -1 && milestoneIndex > -1) {
        let foundIndex = null
        let task = newGoals[goalIndex].milestones[milestoneIndex].tasks.find(
          (task, index) => {
            if (task == data.task) foundIndex = index
            return task == data.task
          },
        )

        if (task) {
          console.log('found task', task)

          newGoals[goalIndex].milestones[milestoneIndex].tasks[
            foundIndex
          ].inSprint = true
        }
      }
    })

    setOpenEditSprintForm(false)

    console.log('new goals are: ', newGoals)
    await updateGoals(newGoals)
    console.log('updated goals with sprint tasks in firebase')

    // if (values['additional-tasks']) {
    //   let newTask = {
    //     description: values['additional-tasks'],
    //     open: true,
    //     milestone: values['radio-group'],
    //   }

    //   console.log('new task is: ', newTask)
    // }

    // call function that updates

    // save sprint tasks to state and send to sprint form
    // setSprintTasks(selectedTasks)
  }

  // const handleSprintTaskComplete = async (completedTask, milestone) => {
  //   console.log('sprint task complete', completedTask, milestone)
  //   let newGoals = [...props.goals]

  //   let goalIndex = newGoals.findIndex((goal) => {
  //     return goal.milestones.includes(milestone)
  //   })

  //   let milestoneIndex = newGoals[goalIndex].milestones.findIndex(
  //     (milestoneObj) => {
  //       return milestoneObj == milestone
  //     },
  //   )

  //   if (goalIndex > -1 && milestoneIndex > -1) {
  //     let foundIndex = null
  //     let task = newGoals[goalIndex].milestones[milestoneIndex].tasks.find(
  //       (_task, index) => {
  //         if (_task == completedTask) foundIndex = index
  //         return _task == completedTask
  //       },
  //     )

  //     if (task) {
  //       console.log('found task', task)

  //       newGoals[goalIndex].milestones[milestoneIndex].tasks[
  //         foundIndex
  //       ].open = false
  //     }
  //   }

  //   await updateGoals(newGoals)
  //   console.log('updated goals with complete sprint tasks in firebase')
  // }
  const handleSprintTaskChange = async (completedTask, milestone, done) => {
    console.log('sprint task complete', completedTask, milestone)
    let newGoals = [...props.goals]

    let goalIndex = newGoals.findIndex((goal) => {
      return goal.milestones.includes(milestone)
    })

    let milestoneIndex = newGoals[goalIndex].milestones.findIndex(
      (milestoneObj) => {
        return milestoneObj == milestone
      },
    )

    if (goalIndex > -1 && milestoneIndex > -1) {
      let foundIndex = null
      let task = newGoals[goalIndex].milestones[milestoneIndex].tasks.find(
        (_task, index) => {
          if (_task == completedTask) foundIndex = index
          return _task == completedTask
        },
      )

      if (task) {
        console.log('found task', task)

        newGoals[goalIndex].milestones[milestoneIndex].tasks[
          foundIndex
        ].open = !done
      }
    }

    await updateGoals(newGoals)
    console.log('updated goals with complete sprint tasks in firebase')
  }

  const updateSprintTask = async (
    completedTask,
    milestone,
    blocked,
    shouldBeInSprint,
  ) => {
    console.log('sprint task updating', completedTask, milestone)
    let newGoals = [...props.goals]

    let goalIndex = newGoals.findIndex((goal) => {
      return goal.milestones.includes(milestone)
    })

    let milestoneIndex = newGoals[goalIndex].milestones.findIndex(
      (milestoneObj) => {
        return milestoneObj == milestone
      },
    )

    if (goalIndex > -1 && milestoneIndex > -1) {
      let foundIndex = null
      let task = newGoals[goalIndex].milestones[milestoneIndex].tasks.find(
        (_task, index) => {
          if (_task == completedTask) foundIndex = index
          return _task == completedTask
        },
      )

      if (task) {
        console.log('found task', task)

        if (blocked !== null) console.log('updating task block')
        newGoals[goalIndex].milestones[milestoneIndex].tasks[
          foundIndex
        ].blocked = blocked

        if (shouldBeInSprint !== null) console.log('updating task inSprint')
        newGoals[goalIndex].milestones[milestoneIndex].tasks[
          foundIndex
        ].inSprint = shouldBeInSprint

        await updateGoals(newGoals)
        console.log('updated goals with complete sprint tasks in firebase')
      }
    }
  }

  const renderChecklist = () => {
    if (!sprintTasks) return null

    let emptyMessage = 'No tasks in this sprint yet!'
    if (!props.goals.length > 0) {
      emptyMessage += ' Start by adding a new goal and milestone!'
    }

    const TASK_ITEMS = [
      {
        label: 'Mark as complete',
        key: 'complete',
      },
      {
        label: 'Mark as blocked',
        key: 'blocked',
      },
      {
        label: 'Remove from sprint',
        key: 'remove',
      },
    ]

    const handleTaskOptionClick = ({ key }) => {
      if (!taskForAction) return
      let item = taskForAction

      switch (key) {
        case 'complete':
          handleSprintTaskChange(item.task, item.milestone, false)
          break
        case 'blocked':
          // mark task blocked
          updateSprintTask(item.task, item.milestone, true, null)
          break
        case 'remove':
          // mark task inSprint as false
          console.log('should remove task', item.task)
          updateSprintTask(item.task, item.milestone, null, false)

        default:
          break
      }
    }

    return (
      <div className="checklist">
        <List
          locale={{ emptyText: emptyMessage }}
          itemLayout="horizontal"
          dataSource={sprintTasks}
          renderItem={(item, index) => {
            console.log('rendering item', item.task)
            return (
              <List.Item
                extra={
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '50px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Dropdown
                      menu={{
                        items: TASK_ITEMS,
                        onClick: handleTaskOptionClick,
                      }}
                      trigger={['click']}
                      onClick={(e) => {
                        setTaskForAction(item)
                      }}
                    >
                      <EllipsisOutlined />
                    </Dropdown>
                    <Checkbox
                      onChange={(e) => {
                        console.log('checked change')
                        handleSprintTaskChange(
                          item.task,
                          item.milestone,
                          e.target.checked,
                        )
                      }}
                      checked={item.task.open == false}
                    />
                  </div>
                }
                style={{
                  cursor: 'pointer',
                }}
              >
                <List.Item.Meta
                  avatar={
                    item.task.owner ? (
                      <Avatar>{item.task.owner[0]}</Avatar>
                    ) : null
                  }
                  title={item.task.description}
                  description={item.milestone.description}
                  onClick={() => {
                    console.log('clicked task')
                    props.showSprintTask({
                      task: item.task,
                      milestone: item.milestone,
                    })
                    // description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                  }}
                />
              </List.Item>
            )
          }}
        />
      </div>
    )
  }

  return (
    <Styles.Wrapper>
      {/* <CSSReset /> */}

      {isMobile ? (
        <MobileOverview.Wrapper></MobileOverview.Wrapper>
      ) : (
        <DesktopOverview.Wrapper>
          <div className="section-header">
            <h1>WEEKLY SPRINT TASKS</h1>
            <button
              onClick={() => {
                if (sprintForm) showSprintForm(true)
                else setOpenEditSprintForm(true)
              }}
            >
              +
            </button>
          </div>
          {renderChecklist()}
          <EditSprintForm
            open={openEditSprintForm}
            onCreate={handleCreateSprint}
            onCancel={() => {
              setOpenEditSprintForm(false)
            }}
            goals={props.goals}
          />
          <SprintForm
            open={showSprintForm}
            onCreate={() => {
              setShowSprintForm(false)
            }}
            onCancel={() => {
              setShowSprintForm(false)
            }}
            goals={props.goals}
          />
        </DesktopOverview.Wrapper>
      )}
    </Styles.Wrapper>
  )
}

const Styles = {
  Wrapper: styled.div`
    padding-top: 1rem;
    width: 30%;
    flex-direction: column;
  `,
}

const DesktopOverview = {
  Wrapper: styled.div`
    // padding: 1rem 3rem;
    // background-color: rgb(49, 50, 51);

    .section-header {
      display: flex;
      justify-content: space-between;
      // justify-content: center;
      align-items: center;
      margin-bottom: 1rem;

      button {
        margin-left: 1rem;
        border-radius: 50%;
        border: none;
        text-align: center;
      }
    }

    .checklist {
      background-color: #fff;
      // display: flex;
      // width: 100%;
      // flex-direction: column;
      // justify-content: space-between;
      // align-items: center;
      // font-color: #fff;
      border: 1px solid #d9d9d9;
      border-bottom: none;
      border-radius: 8px;
      padding: 12px 16px;
      padding-inline-start: 12px;

      .checklist-item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .checklist-item-task {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .task-owner {
            margin-right: 1rem;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .task-name {
            margin-right: 1rem;
          }

          .task-date {
            margin-right: 1rem;
          }
        }
      }
    }

    .Table {
      width: 100%;
    }
  `,
}

const MobileOverview = {
  Wrapper: styled(DesktopOverview.Wrapper)`
    position: fixed;
    width: 100vw;
    bottom: 0;

    justify-content: center;
  `,
}

export default Overview
