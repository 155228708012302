import { Wrapper, Option } from './styled'

const Error = () => {
  return (
    <Wrapper>
      <div className="navbar">
        <div
          className="logo"
          onClick={() => {
            window.location.href = '/'
          }}
        >
          <img src="floreo.png" alt="logo" />
        </div>
        {/* <div className="nav-links">
      <ul>
          <li><a href="/">Home</a></li>
          <li><a href="/about">About Us</a></li>
          <li><a href="/contact">Contact Us</a></li>
          <li><a href="/login">Login</a></li>
      </ul>
  </div> */}
      </div>
      <div className="error">
        <p className="error-msg">
          Hey friend! There was an error with your request 🥺
        </p>
        <img
          className="error-img"
          src={
            'https://media.tenor.com/601zsjqXi-YAAAAd/were-sorry-tony-hayward.gif'
          }
        />
        <Option
          onClick={() => {
            window.location.reload()
          }}
        >
          Refresh and Try Again!
        </Option>
      </div>
    </Wrapper>
  )
}

export default Error
