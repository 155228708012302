import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Button, Table } from 'antd'
import { Resizable } from 'react-resizable'
import ReactDragListView from 'react-drag-listview'

const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation()
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  )
}

class ResizableTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      columns: props.columns.map((col) => {
        let data = {
          title: <span className="dragHandler">{col.title}</span>,
          dataIndex: col.title.toLowerCase(),
          render: (text) => <span>{text}</span>,
        }
        if (col.width) {
          data.width = col.width
        }
        return data
      }),
    }

    console.log('in table data is: ', props.data)

    const that = this
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const columns = [...that.state.columns]
        const item = columns.splice(fromIndex, 1)[0]
        columns.splice(toIndex, 0, item)
        that.setState({
          columns,
        })
      },
      nodeSelector: 'th',
      handleSelector: '.dragHandler',
      ignoreSelector: 'react-resizable-handle',
    }
  }

  components = {
    header: {
      cell: ResizableTitle,
    },
  }

  data = this.props.data

  handleResize = (index) => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns]
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      }
      return { columns: nextColumns }
    })
  }

  handleAdd = () => {
    this.props.onAdd()
  }

  render() {
    const columns = this.state.columns.map((col, index) => ({
      ...col,
      onHeaderCell: (column) => ({
        width: column.width,
        // onResize: this.handleResize(index),
      }),
    }))

    // return (
    //   <ReactDragListView.DragColumn {...this.dragProps}>
    //     <Table
    //       bordered
    //       components={this.components}
    //       pagination={false}
    //       columns={columns}
    //       dataSource={this.data}
    //     />
    //   </ReactDragListView.DragColumn>
    // )
    return (
      <div>
        <Button
          onClick={this.handleAdd}
          type="primary"
          style={{
            marginBottom: 16,
          }}
        >
          Add a Milestone
        </Button>
        <Table
          bordered
          components={this.components}
          pagination={false}
          columns={columns}
          dataSource={this.data}
        />
      </div>
    )
  }
}

export const GoalTable = (props) => {
  console.log('rendering Goal Table')
  console.log('props are: ', props)

  const handleAdd = () => {
    props.onAdd()
  }

  return (
    <div>
      <Button
        onClick={handleAdd}
        type="primary"
        style={{
          marginBottom: 16,
        }}
      >
        Add a Milestone
      </Button>
      <Table
        bordered
        pagination={false}
        columns={props.columns}
        dataSource={props.data}
      />
    </div>
  )
}

export default ResizableTable
