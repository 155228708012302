import { createContext, useContext, useState, useEffect } from 'react'

const SizeContext = createContext()

const SizeProvider = ({ children }) => {
  const [windowDimension, setWindowDimension] = useState(null)

  useEffect(() => {
    setWindowDimension(window.innerWidth)
  }, [])

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const isMobile = windowDimension <= 640

  return (
    <SizeContext.Provider value={{ isMobile, windowDimension }}>
      {children}
    </SizeContext.Provider>
  )
}

const useSize = () => {
  const context = useContext(SizeContext)

  if (context === undefined) {
    throw new Error('useSize must be used within a SizeProvider')
  }

  return context
}

export { SizeProvider, useSize }
