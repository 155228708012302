import { DashboardWrapper, MobileWrapper, DesktopWrapper } from './styled'

import Nav from './dashboard-components/navigation'
import Goals from './dashboard-components/goals'
import Overview from './dashboard-components/overview'
import Metrics from './dashboard-components/metrics'

import { useState, useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../firebaseConfig'
import { onSnapshot, collection, doc } from 'firebase/firestore'
import { db } from '../firebaseConfig'
import { Modal, Spin } from 'antd'
import Settings from '../pages/Settings'
import { useParams } from 'react-router-dom'
import { DashboardAPI } from '../helpers/API-consts'
import { ConfigProvider, theme } from 'antd'
import { useLocation } from 'react-router-dom'

// import { useOutsideAlerter } from 'hooks/outsideAlerter'

const ShowSettingsModal = ({
  open,
  onCreate,
  onCancel,
  dashboardId,
  users,
  invites,
  userInfo,
}) => {
  const [showSettings, setShowSettings] = useState(false)

  console.log(userInfo)
  return (
    <Modal
      open={open}
      title={`Settings`}
      okText="Save Changes"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        onCreate()
      }}
    >
      <Settings
        dashboardId={dashboardId}
        collaborators={users}
        invites={invites}
        userInfo={userInfo}
      />
    </Modal>
  )
}

const FullScreenLoader = ({ open }) => {
  // return full screen modal with loading spinner

  const { token } = theme.useToken()

  console.log('loader should be ', open)
  return (
    <div
      id="fullscreen-loader"
      style={{
        display: open ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        position: 'absolute',
        zIndex: 100,
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
      }}
    >
      {/* <Modal
        open={open}
        closable={false}
        footer={null}
        centered={true}
        width={0}
        bodyStyle={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
      </Modal> */}
      <Spin size="large" tip="Loading..." />
    </div>
  )
}

const Dashboard = (props) => {
  const [windowDimension, setWindowDimension] = useState(null)
  const [mobileSection, setMobileSection] = useState(null)
  const [GOALS, setGOALS] = useState([])
  const [userIds, setUserIds] = useState(null)
  const [USERS, setUSERS] = useState([])
  const [INVITES, setINVITES] = useState([])
  const [NAME, setNAME] = useState(null)
  const [user, loading, error] = useAuthState(auth)
  const [showSettings, setShowSettings] = useState(false)
  const { dashboardId } = useParams()
  const [showSprintTask, setShowSprintTask] = useState(null)
  const [loadingData, setLoadingData] = useState(false)

  console.log('dashboard props', props)

  const location = useLocation()
  const userInfo = location.state
  console.log(userInfo)

  const handleSettingsClicked = () => {
    setShowSettings(true)
  }

  const handleSettingsUpdate = () => {
    setShowSettings(false)
  }

  const fetchUsers = async () => {
    console.log('fetching dashboard users')

    try {
      let response = await fetch(DashboardAPI + '/readDashboardUsers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ dashboardId: dashboardId }),
      })

      let _users = await response.json()
      console.log('users', _users)
      if (_users) setUSERS(_users)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setWindowDimension(window.innerWidth)
  }, [])

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (!user) return (window.location.href = '/login')
    else {
      if (user && !loading) {
        console.log(user.uid, 'logged in')

        console.log(process.env.NODE_ENV)
      }
    }
  }, [user])

  // useEffect to set up listener for dashboard changes
  useEffect(() => {
    //set up listener for changes to dashboard and update state GOALS with dashboard.goals data from snapshot

    const dashboardRef = doc(db, 'dashboards', dashboardId)

    // const dashboardRef = doc(db, 'dashboards', props.dashboardId)

    const unsubscribe = onSnapshot(dashboardRef, (doc) => {
      console.log('received dashboard update')
      console.log('Current data: ', doc.data())
      setGOALS(doc.data().goals)
      setNAME(doc.data().name)

      if (doc.data().users && userIds !== doc.data().users) {
        setUserIds(doc.data().users)
        fetchUsers()
      }

      setINVITES(doc.data().invites)

      setLoadingData(false)
      console.log('should hide loader')
    })
    return () => unsubscribe()
  }, [])

  const showLoading = () => {
    console.log('starting loader')
    setLoadingData(true)
  }

  const renderMobileSection = (section) => {
    switch (mobileSection) {
      case 'goals':
        return (
          <Goals
            goals={GOALS}
            updateGoals={setGOALS}
            dashboardId={dashboardId}
            handleLoading={showLoading}
          />
        )
      case 'overview':
        return <Overview goals={GOALS} />
      // case 'metrics':
      //   return <Metrics goals={GOALS} />
      default:
        return <div>Mobile</div>
    }
  }

  const isMobile = windowDimension <= 640
  return (
    <DashboardWrapper id="container">
      <Nav name={NAME} onSettingsClicked={handleSettingsClicked} />
      {/* {isMobile && renderMobileSection(mobileSection)} */}
      <DesktopWrapper>
        {true && (
          <>
            <Goals
              goals={GOALS}
              updateGoals={setGOALS}
              dashboardId={dashboardId}
              users={USERS}
              showSprintTask={showSprintTask}
              handleLoading={showLoading}
              loading={loadingData}
            />
            <Overview
              goals={GOALS}
              dashboardId={dashboardId}
              users={USERS}
              showSprintTask={setShowSprintTask}
              handleLoading={showLoading}
            />
          </>
        )}

        {/* <Metrics goals={GOALS} /> */}
        <ShowSettingsModal
          open={showSettings}
          onCancel={() => {
            setShowSettings(false)
          }}
          onCreate={handleSettingsUpdate}
          dashboardId={dashboardId}
          invites={INVITES}
          users={USERS}
          userInfo={userInfo}
        />
        {<FullScreenLoader open={loadingData} />}
      </DesktopWrapper>
    </DashboardWrapper>
  )
}

export default Dashboard
