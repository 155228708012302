import { auth } from '../firebaseConfig'

import { sendSignInLinkToEmail } from 'firebase/auth'

import moment from 'moment'

export const sendSignInLink = (email, setLoginLoading, setLoginError) => {
  const API = 'https://sendsigninlink-witz2rdnhq-uc.a.run.app'

  // send a POST request to the API to send the user email and wait for a response
  fetch(API, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data)
      if (data.success) {
        // if the response is successful, send the user an email with a link to sign in
        window.localStorage.setItem('emailForSignIn', email)
        setLoginLoading(false)
        setLoginError('')
      }
    })
    .catch((error) => {
      console.log(error)
      setLoginLoading(false)
      setLoginError(error.message)
    })

  // sendSignInLinkToEmail(auth, email, {
  //   url: 'http://localhost:3000/verify',
  //   handleCodeInApp: true,
  // })
  //   .then(() => {
  //     window.localStorage.setItem('emailForSignIn', email)
  //     setLoginLoading(false)
  //     setLoginError('')
  //   })
  //   .catch((error) => {
  //     console.log(error)
  //     setLoginLoading(false)
  //     setLoginError(error.message)
  //   })
}

export const sendLeadGeneratorLink = (email) => {
  // send fetch request to API to send the user email and wait for a response
  fetch('https://sendleadgeneratorlink-witz2rdnhq-uc.a.run.app', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data)
      if (data.success) {
        // if the response is successful, send the user an email with a link to sign in
        // get current date and set it to the user's local storage
        const date = moment().format('YYYY-MM-DD')
        window.localStorage.setItem('leadGenDate', date)
      }
    })
}
