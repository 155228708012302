import { useState, useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, db } from '../../firebaseConfig'
import {
  isSignInWithEmailLink,
  signInWithEmailLink,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from 'firebase/auth'

import { setDoc, doc, collection } from 'firebase/firestore'
import { sendSignInLink } from '../../helpers'
import { useNavigate } from 'react-router-dom'
import { PageWrapper } from './styled'
import { Form, Input, Button, Checkbox, Spin } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { GoogleOutlined } from '@ant-design/icons'

import { DashboardAPI } from '../../helpers/API-consts'

import Nav from '../../dashboard/dashboard-components/navigation'

export const AuthForm = () => {
  const [user, loading, error] = useAuthState(auth)
  const [loadingStatus, setLoadingStatus] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [usePassword, setUsePassword] = useState(true)
  const [isSignIn, setIsSignIn] = useState(true)

  // check params
  const { encodedURI, inviteId, dashboardId } = useParams()

  const navigate = useNavigate()

  // const toggleLoading = () => {
  //   const loadingStrings = ['.', '..', '...']
  //   let index = 0
  //   setInterval(() => {
  //     setLoadingMessage(`${loadingStrings[index]}`)
  //     index++
  //     if (index === loadingStrings.length) index = 0
  //   }, 500)
  // }

  // const checkInviteStatus = async () => {
  //   try {
  //     let response = await fetch(DashboardAPI + 'checkInviteStatus', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ inviteId, dashboardId }),
  //     })
  //     let inviteStatus = await response.json()
  //     console.log('inviteStatus', inviteStatus)
  //     if (inviteStatus) setInviteStatus(inviteStatus)
  //     setLoadingStatus(false)
  //   } catch (error) {
  //     console.log(error)
  //     setLoadingStatus(false)
  //   }
  // }

  // useEffect(() => {
  //   // check if inviteId and dashboardId are present in the url

  //   if (!inviteId || !dashboardId) {
  //     console.log('no invite')
  //     setLoadingStatus(false)
  //   }

  //   if (loadingStatus && inviteId && dashboardId) {
  //     // check if the invite is still valid
  //     console.log('checking invite')
  //     checkInviteStatus()
  //   } else {
  //     console.log('should be setting invite to no')
  //     setLoadingStatus(false)
  //   }
  // })

  //   useEffect(() => {
  //     if (isSignIn) {
  //       //   let interval = toggleLoading()
  //       if (user) {
  //         // navigate('/')
  //       } else {
  //         if (isSignInWithEmailLink(auth, window.location.href)) {
  //           let email = localStorage.getItem('emailForSignIn')
  //           if (!email) {
  //             email = prompt('Please provide your email for confirmation')
  //             window.localStorage.setItem('emailForSignIn', email)
  //           }
  //           signInWithEmailLink(auth, email, window.location.href)
  //             .then((result) => {
  //               console.log(result.user)
  //               window.localStorage.removeItem('emailForSignIn')
  //               //   navigate('/')
  //             })
  //             .catch((error) => {
  //               console.log(error)
  //               //   navigate('/login')
  //             })
  //         }
  //       }
  //       return () => {
  //         // setLoading(false)
  //         // clearInterval(interval)
  //       }
  //     }
  //   })

  const handleSignIn = () => {
    if (!usePassword) {
      //   const actionCodeSettings = {
      //     url: 'http://localhost:3000/login',
      //     handleCodeInApp: true,
      //   }
      //   auth
      //     .sendSignInLinkToEmail(email, actionCodeSettings)
      //     .then(() => {
      //       window.localStorage.setItem('emailForSignIn', email)
      //       alert(`Email sent to ${email}`)
      //     })
      //     .catch((error) => {
      //       console.log(error)
      //     })
      sendSignInLink(email)
    } else {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user
          // ...
          if (user)
            navigate('/', { state: { inviteId, toDashboard: dashboardId } })

          // navigate('/')
          // ...
        })
        .catch((error) => {
          console.log(error)
          const errorCode = error.code
          const errorMessage = error.message
          console.log(errorCode, errorMessage)
        })
    }
  }

  const handleSignUp = async () => {
    //
    try {
      let userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      )
      // Signed in
      const user = userCredential.user
      // create a POST request to DashboardAPI to create a dashboard for the user
      // const dashboard = {
      //   uid: user.uid,
      //   name: 'Floreo',
      // }
      console.log(DashboardAPI + 'createDashboard')

      // update user doc with uid with name
      console.log('creating user', name, user.uid)

      let userRef = doc(db, 'users', user.uid)
      await setDoc(userRef, {
        name: name,
        dashboards: [],
        uid: user.uid,
      })

      // let result = await response.json()
      // console.log('result', result)

      let dashboardName = decodeURIComponent(encodedURI)
      console.log(dashboardName)
      navigate('/', {
        state: { dashboardName, inviteId, toDashboard: dashboardId },
      })
    } catch (error) {
      const errorCode = error.code
      const errorMessage = error.message
      console.log(error)
      alert('Please try again!')
      // ..
    }
  }

  const handleSignOut = () => {
    auth.signOut()
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const handleIsSignInChange = (e) => {
    setIsSignIn(e.target.value)
  }

  const googleProvider = new GoogleAuthProvider()
  const signInWithGoogle = async () => {
    try {
      const res = await signInWithPopup(auth, googleProvider)
      const user = res.user
      // const q = query(collection(db, "users"), where("uid", "==", user.uid));
      // const docs = await getDocs(q);
      // if (docs.docs.length === 0) {
      //   await addDoc(collection(db, "users"), {
      //     uid: user.uid,
      //     name: user.displayName,
      //     authProvider: "google",
      //     email: user.email,
      //   });
      // }

      let dashboardName = decodeURIComponent(encodedURI)
      console.log(dashboardName)

      navigate('/', {
        state: { inviteId, toDashboard: dashboardId, dashboardName },
      })
    } catch (err) {
      console.error(err)
      alert(err.message)
    }
  }

  return (
    <Styles.Wrapper>
      <Nav />

      <Form
        name="basic"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={isSignIn ? handleSignIn : handleSignUp}
      >
        <div className="form-header">
          <div className="form-header-title">
            {isSignIn ? 'Sign In' : 'Sign Up'}
          </div>
          <div className="form-header-subtitle"></div>
        </div>
        {/*Button with Google Icon*/}
        {
          <Form.Item>
            <Button
              type="primary"
              icon={<GoogleOutlined />}
              onClick={signInWithGoogle}
            >
              Google
            </Button>
          </Form.Item>
        }
        {'Or Use'}
        {!isSignIn && (
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please input your name!' }]}
          >
            <Input
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
          </Form.Item>
        )}
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input
            onChange={(e) => {
              setEmail(e.target.value)
            }}
          />
        </Form.Item>

        {true && (
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password
              onChange={(e) => {
                setPassword(e.target.value)
              }}
            />
          </Form.Item>
        )}

        {/* {isSignIn && (
            <Form.Item>
              <Checkbox
                checked={usePassword}
                onChange={() => setUsePassword(!usePassword)}
              >
                Use password
              </Checkbox>
            </Form.Item>
          )} */}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {isSignIn ? 'Sign In' : 'Sign Up'}
          </Button>
        </Form.Item>
        <div className="form-footer">
          <div className="form-footer-text">
            {isSignIn ? 'Don’t have an account?' : 'Already have an account?'}
          </div>
          <div
            className="form-footer-link"
            onClick={() => setIsSignIn(!isSignIn)}
          >
            {isSignIn ? 'Sign Up' : 'Sign In'}
          </div>
        </div>
      </Form>
    </Styles.Wrapper>
  )
}

const Styles = {
  Wrapper: styled.div`
    .login-form {
      background-color: #f5f5f5;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      max-width: 400px;
      margin: 0 auto;

      .form-header {
        margin-bottom: 1rem;
      }

      .form-header-title {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
    }

    .loading-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
    }
  `,
}
