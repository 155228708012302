import styled from 'styled-components/macro'
import '../fonts/Manrope-VariableFont_wght.ttf'

export const DashboardWrapper = styled.div`
  // height: 100%;
  // width: 100%;
  // background-color: black;
  color: #fff;
  font-family: 'Manrope';
`

export const DesktopWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

const DashboardSection = styled.div`
  background-color: #231f20;
  color: #23c8ce;
  border-radius: 5px;
  padding: 20px;
  filter: ${(props) => (props.onboarding ? 'blur(6px)' : 'none')};
  font-size: 250%;
  font-family: 'Manrope';
  &:hover,
  &:focus {
    background-color: ${(props) => (props.onboarding ? '#231f20' : ' #f7ff00')};
    color: ${(props) => (props.onboarding ? '#23c8ce' : '#231f20')};
    border-left: ${(props) =>
      props.onboarding ? 'none' : '5px solid #ff0066'};
  }
`

export const MilestonesWrapper = styled(DashboardSection)`
  grid-column: col1-start / col3-start;
  grid-row: row1-start;
`
export const ObjectivesWrapper = styled(DashboardSection)`
  grid-column: col3-start;
  grid-row: row1-start / row2-end;
`
export const TrainingWrapper = styled(DashboardSection)`
  grid-column: col3-start;
  grid-row: row2-end / row3-end;
  margin-top: 10px;
`
export const ResourcesWrapper = styled(DashboardSection)`
  grid-column: col1-start;
  grid-row: row2-start / row3-end;
`
export const GoalsWrapper = styled(DashboardSection)`
  grid-column: col2-start;
  grid-row: row2-start / row3-end;
`

export const TrainingMessage = styled.div`
  font-size: 50%;
  margin-top: 20px;
  color: ${(props) => (props.hover ? '#231f20' : '#fff')};
`
