import styled from 'styled-components'

import Popup from 'reactjs-popup'

export const StyledPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  //   &-overlay {
  //     ...;
  //   }
  // use your custom style for ".popup-content"
  &-content {
    border-radius: 15px;
    width: 60%;

    form {
      display: block;

      .box {
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1),
          0 0 0 1px rgba(10, 10, 10, 0.1);
        display: block;
        padding: 1.25rem;
      }

      span {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background-color: #f5f5f5;
        border-radius: 290486px;
        color: #4a4a4a;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        font-size: 0.75rem;
        height: 2em;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        line-height: 1.5;
        padding-left: 0.875em;
        padding-right: 0.875em;
        margin-left: 0.5em;
        vertical-align: top;
        white-space: nowrap;
        background-color: #23d160;
        color: #fff;
      }

      label {
        color: #363636;
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: 700;
      }

      .label:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      textarea {
        display: block;
        line-height: 1.25;
        max-height: 600px;
        max-width: 100%;
        min-height: 120px;
        min-width: 100%;
        padding: 0.625em;
        resize: vertical;
      }
    }

    @media (max-width: 425px) {
      width: 90%;
    }
  }
`

export const PopUpMessage = styled.div`
  background-color: white;
  color: black;
  border-radius: 15px;
  width: 100%;
  height: 33%;
  margin-top: 5%;
  margin-bottom: 5%;

  h1 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 3%;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 3%;

    .emphasis {
      font-weight: 700;
    }
  }

  #message-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 5%;

    span {
      cursor: pointer;
    }
  }
`

export const HelpWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`

export const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 3%;
    padding-bottom: 0;
    background-color: transparent;
    color: #444;
    position: relative;
  }

  .navbar .logo {
    width: 150px;
  }

  .navbar .logo img {
    width: 100%;
    cursor: pointer;
  }

  h1 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 3%;
    margin-left: 3%;
    // position: absolute;
    // top: 168px;
    // left: 65px;

    @media (max-width: 425px) {
      font-size: 1.5rem;
      margin-top: 10%;
    }
  }

  p {
    font-size: 1.5rem;
    font-weight: 400;
    margin-left: 3%;
    margin-bottom: 3%;
  }

  .opener {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      align-self: center;
      text-align: center;
      font-size: 1.5rem;
    }
  }

  #opener-btn {
    width: 200px;
  }

  .recommendation {
    p {
      font-size: 1.25rem;
    }
    .save-button {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      span {
        text-decoration: underline;
      }
    }
  }

  .error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px;

    .error-msg {
      font-size: 1.5rem;
      font-weight: 700;
    }

    .error-img {
      width: 25%;
      margin-bottom: 3%;
    }

    @media (max-width: 500px) {
      .error-msg {
        font-size: 1rem;
      }
      .error-img {
        width: 50%;
      }
    }
  }
`

export const OptionsWrapper = styled.div`
  //   position: absolute;
  //   top: 263px;
  //   left: 65px;
  margin-left: 3%;
  margin-bottom: 3%;
  // width: 1200px;
  // max-width: 1200px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 20px;
  justify-content: space-around;
`

export const Option = styled.div`
  width: 30%;
  min-height: 75px;
  font-size: 1em;
  font-weight: 700;
  background-color: ${(props) =>
    props.action == true
      ? 'rgba(5, 12, 255, 0.2)'
      : 'rgba(217, 217, 217, 0.2)'};
  border-radius: 15px;
  display: flex;
  position: relative;
  z-index: 10;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: ${(props) => (props.large ? '206px' : '75px')};
  visibility: ${(props) => (props.hide ? 'hidden' : 'visible')};
  border: ${(props) => (props.selected ? '2px solid #05c46b' : 'none')};

  .tooltip {
    position: absolute;
    background-color: #fff;
    border-radius: 50%;
    bottom: 0;
    right: 0;
    z-index: 31;
    // width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 30px;
  }

  :hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.action ? 'rgba(5, 12, 255, 0.4)' : 'rgba(217, 217, 217, 0.4'};
  }

  @media (max-width: 425px) {
    height: ${(props) => (props.large ? '150px' : '30px')};
    font-size: 0.8em;
  }
`

export const GoalOption = styled(Option)`
  height: 206px;

  @media (max-width: 425px) {
    height: 150px;
  }
`

export const ActionOption = styled(Option)`
  background-color: rgba(5, 12, 255, 0.2);

  :hover {
    background-color: rgba(5, 12, 255, 0.4);
  }
`

export const ActionsWrapper = styled(OptionsWrapper)`
  // width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: 'space-between';
`

export const UpgradeWrapper = styled(OptionsWrapper)`
  // width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: 'space-between';
  align-items: center;
  margin-top: 3%;
`

export const UpgradeOption = styled(Option)`
  background-color: rgba(235, 172, 255, 0.3);

  :hover {
    background-color: rgba(235, 172, 255, 0.5);
  }
`

export const LogoOption = styled(Option)`
  background-color: transparent;
  background-image: url(${(props) => props.img});
  background-size: contain;
  background-repeat: no-repeat;

  :hover {
    background-color: transparent;
  }
`

export const InputForm = styled.form`
  display: block;
  margin-left: 3%;
  margin-bottom: 3%;

  .box {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    display: block;
    padding: 1.25rem;
  }

  span {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 290486px;
    color: #4a4a4a;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 0.75rem;
    height: 2em;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 1.5;
    padding-left: 0.875em;
    padding-right: 0.875em;
    margin-left: 0.5em;
    vertical-align: top;
    white-space: nowrap;
    background-color: #23d160;
    color: #fff;
  }

  label {
    color: #363636;
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
  }

  .label:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  textarea {
    display: block;
    line-height: 1.25;
    max-height: 600px;
    max-width: 90%;
    min-height: 60px;
    min-width: 90%;
    padding: 0.625em;
    resize: vertical;
  }

  #timeline,
  #status {
    margin-bottom: 1%;
    margin-left: 0;
    margin-top: 1.5%;
  }
`
