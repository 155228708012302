import { PageWrapper } from './styled'
import BYOB from '../../byob'
import Quiz from '../../byob/quiz'
import Dashboard from '../../dashboard'
import { useEffect, useState } from 'react'
import PricingContainer from '../../pricing'
import { useLocation } from 'react-router-dom'
import { DashboardAPI } from '../../helpers/API-consts'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, db } from '../../firebaseConfig'
import { Space, Spin } from 'antd'
import { useNavigate } from 'react-router-dom'
import { Layout, Menu, theme, Button, Modal, Form, Input } from 'antd'
import { Card, Col, Row } from 'antd'
import Nav from '../../dashboard/dashboard-components/navigation'
import { onSnapshot, collection, doc } from 'firebase/firestore'
import { set } from 'date-fns'

const { Header, Content, Footer, Sider } = Layout

const ShowSettingsModal = ({
  open,
  onCreate,
  onCancel,
  dashboardId,
  name,
  email,
}) => {
  const [showSettings, setShowSettings] = useState(false)

  const [userName, setUserName] = useState(null)

  const [form] = Form.useForm()

  return (
    <Modal
      open={open}
      title={`Settings`}
      okText="Save Changes"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields()
            onCreate(values)
          })
          .catch((info) => {
            console.log('Validate Failed:', info)
          })
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: 'public',
        }}
      >
        {/* <Form.Item
      name="upload"
      label="Upload"
      valuePropName="fileList"
      getValueFromEvent={normFile}
      extra="longgggggggggggggggggggggggggggggggggg"
    >
      <Upload name="logo" action="/upload.do" listType="picture">
        <img src="https://gw.alipayobjects.com/zos/rmsportal/OKJXDXrmkNshAMvwtvhu.svg" alt="avatar" style={{ width: '100%' }} />
        <Button icon={<UploadOutlined />}>Click to upload</Button>
      </Upload>
    </Form.Item> */}
        <Form.Item name="name" label="Name">
          <Space.Compact style={{ width: '100%' }}>
            <Input
              type="input"
              onChange={(e) => setUserName(e.target.value)}
              value={userName}
              placeHolder={name}
            />
          </Space.Compact>
        </Form.Item>
        <p>{email} </p>
      </Form>
    </Modal>
  )
}

// const ShowOnboardingModal = ({ open, onCreate, onCancel }) => {

//   const [userName, setUserName] = useState(null)

//   const [form] = Form.useForm()

//   return (
//     <Modal
//       open={open}
//       title={`Welcome to TBD - The Best Dashboard`}
//       okText="Get Started"
//       cancelText="Cancel"
//       onCancel={onCancel}
//       onOk={() => {
//         form
//           .validateFields()
//           .then((values) => {
//             form.resetFields()
//             onCreate(values)
//           })
//         }}
//     >
//       <Form
//         form={form}
//         layout="vertical"
//         name="form_in_modal"
//       >
//         <Form.Item name="name" label="What's your name?" rules={[
//           {
//             required: true,
//           },
//         ]}>
//           <Space.Compact style={{ width: '100%' }}>
//             <Input
//               type="input"
//               onChange={(e) => setUserName(e.target.value)}
//               value={userName}
//               placeHolder={name}
//             />
//           </Space.Compact>
//         </Form.Item>
//       </Form>
//     </Modal>

//   )
// }

const HomeScreen = (props) => {
  const [collapsed, setCollapsed] = useState(false)
  const {
    token: { colorBgContainer },
  } = theme.useToken()

  const navigate = useNavigate()

  const [user, loading, error] = useAuthState(auth)
  const [showSettings, setShowSettings] = useState(false)
  const [loadingData, setLoadingData] = useState(true)

  // const acceptInvite = async () => {
  //   try {
  //     let response = await fetch(DashboardAPI + 'acceptInvite', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         inviteId: props.inviteStatus.inviteId,
  //         dashboardId: props.inviteStatus.dashboardId,
  //         email: auth.currentUser.email,
  //       }),
  //     })
  //     let inviteStatus = await response.json()
  //     console.log('inviteStatus', inviteStatus)
  //     if (inviteStatus) setInviteStatus(inviteStatus)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // const fetchDashboardNames = async () => {
  //   let dashboardNames = []

  //   props.dashboards.map((dashboardId) => {
  //     doc(db, 'dashboards', dashboardId).then((doc) => {
  //       dashboardNames.push(doc.data().name)
  //     }
  //     )
  //   })

  //   return dashboardNames
  // }

  const addToDashboard = async () => {
    // if (!props.user) return

    // const user = auth.currentUser
    // let userName = ""
    // if(!props.userInfo.name || !user.displayName) {
    //   userName = user.email
    // }
    setLoadingData(true)
    console.log('trying to add to dashboard', props.invite)
    try {
      let response = await fetch(DashboardAPI + 'addDashboardUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          dashboardId: props.invite.toDashboard,
          dashboardName: props.invite.dashboardName,
          uid: user.uid,
          email: user.email,
          name: props.userInfo.name || user.displayName || user.email,
        }),
      })

      setLoadingData(false)

      navigate('/dashboard/' + props.invite.toDashboard, {
        state: { userInfo: props.userInfo },
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (props.invite && props.invite.status === 'approved') {
      if (user && !loading) addToDashboard()
    } else setLoadingData(false)
  }, [user, loading, props.invite])

  const handleSettingsClicked = (values) => {
    setShowSettings(true)
  }

  function getItems() {
    props.dashboards.map(
      ({ dashboardId, dashboardName, dashboardIcon }, index) => {
        return {
          key: index + 1,
          id: dashboardId,
          icon: dashboardIcon,
          label: dashboardName,
        }
      },
    )
  }
  const items = getItems()

  const createDashboard = async (dashboardName) => {
    setLoadingData(true)
    try {
      let response = await fetch(DashboardAPI + 'createDashboard', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid: user.uid, name: dashboardName }),
      })
      let dashboard = await response.json()
      console.log('dashboard', dashboard)
      if (dashboard) {
        setLoadingData(false)
        navigate('/dashboard/' + dashboard.dashboardId)
      }
    } catch (error) {
      console.log(error)
      setLoadingData(false)
    }
  }

  const handleSettingsUpdate = async (values) => {
    try {
      let response = await fetch(DashboardAPI + 'updateUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid: user.uid, data: { name: values.name } }),
      })
      let result = await response.json()
      console.log('result', result)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Nav onSettingsClicked={handleSettingsClicked} />
      {!loadingData ? (
        <Layout
          style={{
            minHeight: '80vh',
          }}
        >
          {/* <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          defaultSelectedKeys={['1']}
          mode="inline"
          items={items}
          onClick={(e) => {
            console.log(e)
            navigate('/dashboard/' + e.key)
          }}
        />
      </Sider> */}
          {user && !loading && (
            <Layout>
              {/* <Header
          style={{
            background: 'blue',
            margin: 3,
          }}
        >
          <Nav />
        </Header> */}
              <Content
                style={{
                  margin: '16px 16px',
                }}
              >
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      let newDashboardName = prompt(
                        'Enter a name for your new dashboard',
                      )
                      if (newDashboardName) {
                        createDashboard(newDashboardName)
                      }
                    }}
                  >
                    Create a new dashboard
                  </Button>
                </Space>

                <div
                  style={{
                    padding: 24,
                    minHeight: 360,
                    // background: colorBgContainer,
                    marginTop: 24,
                  }}
                >
                  Your Dashboards
                  <Row gutter={16} style={{ marginTop: 16 }}>
                    {props.dashboards?.map(({ dashboardId, name }) => {
                      return (
                        <Col span={8}>
                          <Card
                            theme="dark"
                            hoverable
                            bordered={false}
                            key={dashboardId}
                            onClick={() => {
                              console.log(dashboardId)
                              navigate('/dashboard/' + dashboardId, {
                                state: { userInfo: props.userInfo },
                              })
                            }}
                          >
                            <div
                              style={{
                                fontSize: 24,
                                fontWeight: 'bold',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              {name}
                            </div>
                          </Card>
                        </Col>
                      )
                    })}
                  </Row>
                </div>
              </Content>
              <Footer
                style={{
                  textAlign: 'center',
                }}
              >
                TBD.HOW © 2023 Created by Floreo Labs
              </Footer>
            </Layout>
          )}
        </Layout>
      ) : (
        <div className="loading-container">
          <Spin size="large" />
        </div>
      )}
      <ShowSettingsModal
        open={showSettings}
        onCancel={() => {
          setShowSettings(false)
        }}
        onCreate={handleSettingsUpdate}
        name={props.userInfo?.name || null}
        email={auth.currentUser.email}
      />
    </>
  )
}

const Home = (props) => {
  const [onboarding, setOnboarding] = useState(false)
  const [context, setContext] = useState({})
  const location = useLocation()
  const { dashboardName, inviteId, toDashboard } = location.state || {
    dashboardName: null,
    inviteId: null,
    toDashboard: null,
  }

  console.log('toDashboard', toDashboard, dashboardName, inviteId)

  const [user, loading, error] = useAuthState(auth)
  const [dashboards, setDashboards] = useState(null)
  const [dashboardId, setDashboardId] = useState(null)
  const [userInfo, setUserInfo] = useState(null)
  // const navigate = useNavigate()
  const [inviteStatus, setInviteStatus] = useState(null)
  const [loadingStatus, setLoadingStatus] = useState(true)

  const onOnboardingDone = (values) => {
    setOnboarding(false)
    setContext(values)
  }

  useEffect(() => {
    if (!onboarding && !user && !loading)
      return (window.location.href = '/login')
    else {
      if (user && !loading) {
        const userRef = doc(db, 'users', user.uid)

        const unsubscribe = onSnapshot(userRef, (doc) => {
          console.log('received user update')
          console.log('Current data: ', doc.data())
          setUserInfo(doc.data())
          setDashboards(doc.data().dashboards)
          setLoadingStatus(false)

          if (inviteId && toDashboard) checkInviteStatus()
        })
        return () => unsubscribe()
      }
    }
  }, [user, loading])

  // useEffect to set up listener for user profile changes

  // useEffect(() => {
  //   //set up listener for changes to user profile and update state user with user data from snapshot

  //   const userRef = doc(db, 'users', user.uid)

  //   const unsubscribe = onSnapshot(userRef, (doc) => {
  //     console.log('received user update')
  //     console.log('Current data: ', doc.data())
  //     setUserInfo(doc.data())
  //   })
  //   return () => unsubscribe()
  // }, [])

  const checkInviteStatus = async () => {
    try {
      let response = await fetch(DashboardAPI + 'checkInviteStatus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          inviteId,
          dashboardId: toDashboard,
          email: auth.currentUser.email,
        }),
      })
      let inviteStatus = await response.json()
      console.log('inviteStatus', inviteStatus)
      if (inviteStatus) setInviteStatus(inviteStatus.inviteStatus)
      setLoadingStatus(false)
    } catch (error) {
      console.log(error)
      setLoadingStatus(false)
    }
  }

  // return

  return (
    <PageWrapper>
      {onboarding && (
        <Quiz onDone={onOnboardingDone} sessionId={props.sessionId} />
      )}
      {!onboarding && (
        <>
          {/* {user && !loading && dashboardId && (
            <Dashboard dashboardId={dashboardId} />
          )} */}

          {user && !loading && !loadingStatus && dashboards && (
            <HomeScreen
              userInfo={userInfo}
              dashboards={dashboards}
              invite={{ status: inviteStatus, toDashboard, dashboardName }}
            />
          )}
          {(loading || loadingStatus || !dashboards) && (
            <div className="loading-container">
              <Spin size="large" />
            </div>
          )}
        </>
      )}
    </PageWrapper>
  )
}

export default Home
