import { PageWrapper } from './styled'
import { Form, Input, Space, Button, Spin, List, Avatar } from 'antd'
import styled from 'styled-components'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebaseConfig'
import { useState, useEffect } from 'react'
import { DashboardAPI } from '../../helpers/API-consts'
import { doc, onSnapshot, updateDoc } from 'firebase/firestore'
import { db } from '../../firebaseConfig'

const Settings = (props) => {
  const [user, loading, error] = useAuthState(auth)
  const [dashboardName, setDashboardName] = useState('')
  const [email, setEmail] = useState('')
  const [data, setData] = useState([])
  const [users, setUsers] = useState([])
  const [loadingStatus, setLoadingStatus] = useState(true)
  const [loadingData, setLoadingData] = useState(true)
  const [dashboardInfo, setDashboardInfo] = useState(null)
  const [invitations, setInvitations] = useState(null)

  // alert('should show settings')

  const updateDashboardInfo = async () => {
    setLoadingStatus(true)
    try {
      let apiResponse = await fetch(DashboardAPI + 'updateDashboard', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          dashboardId: props.dashboardId,
          name: dashboardName,
        }),
      })
      let result = await apiResponse.json()
      setLoadingStatus(false)
      console.log(result)
    } catch (error) {
      console.log(error)
      setLoadingStatus(false)
    }
  }

  useEffect(() => {
    console.log(props.collaborators)
    console.log({ uid: user.uid, loading, dashboardInfo })
    if (!user && !loading) return (window.location.href = '/login')
    else {
      if (user && !loading) {
        console.log(user.uid, 'logged in')
        // fetchDashboardInfo()

        // set up listener for dashboard changes
        const dashboardRef = doc(db, 'dashboards', props.dashboardId)

        const unsubscribe = onSnapshot(dashboardRef, (doc) => {
          console.log('received dashboard update')
          console.log('Current data: ', doc.data())
          setDashboardInfo(doc.data())
          setDashboardName(doc.data().name)
          setUsers(doc.data().users)
          setInvitations(doc.data().invitations)
          setLoadingStatus(false)
        })

        return () => unsubscribe()
      }
    }
  }, [user, loading])

  const addUser = async () => {
    setLoadingStatus(true)
    console.log(props.userInfo)
    let dataForInvite = {
      dashboardId: props.dashboardId,
      email: email,
      name: props.userInfo.userInfo.name,
      userName: props.userInfo.userInfo.name,
      dashboardName: dashboardName,
    }

    console.log('dataForInvite', dataForInvite, props.userInfo)

    try {
      let response = await fetch(
        'https://senddashboardinvite-witz2rdnhq-uc.a.run.app',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataForInvite),
        },
      )
      let result = await response.json()
      console.log('result', result)

      console.log('updating invites')
      // add email to dashboard invites array
      let oldInvites = []
      if (props.invites) oldInvites = [...props.invites]

      await updateDoc(doc(db, 'dashboards', props.dashboardId), {
        invites: [...oldInvites, email],
      })

      setLoadingStatus(false)
    } catch (error) {
      console.log(error)
      setLoadingStatus(false)
    }
  }

  const removeUser = async (_user) => {
    setLoadingStatus(true)
    if (_user.uid === users[0].uid) {
      if (user.uid === _user.uid) {
        alert(
          'You cannot remove yourself from the dashboard because you are the owner',
        )
        return
      }
      alert('You cannot remove the owner of the dashboard')
      return
    }

    try {
      let response = await fetch(DashboardAPI + 'removeDashboardUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ dashboardId: props.dashboardId, uid: user.uid }),
      })
      let result = await response.json()
      console.log('result', result)
      setLoadingStatus(false)
    } catch (error) {
      console.log(error)
      setLoadingStatus(false)
    }
  }

  const removeinvite = async (email) => {
    setLoadingStatus(true)

    try {
      let oldInvites = []
      if (props.invites) oldInvites = [...props.invites]

      oldInvites = oldInvites.filter((invite) => invite !== email)

      await updateDoc(doc(db, 'dashboards', props.dashboardId), {
        invites: oldInvites,
      })

      let response = await fetch(DashboardAPI + 'removeInvite', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          dashboardId: props.dashboardId,
          email: email,
        }),
      })
      let result = await response.json()
      console.log('result', result)
      setLoadingStatus(false)
    } catch (error) {
      console.log(error)
      setLoadingStatus(false)
    }
  }

  return (
    <PageWrapper>
      {loading ||
        !dashboardInfo ||
        (loadingStatus && (
          <div className="loading-container">
            <Spin size="large" />
          </div>
        ))}
      {user && !loading && dashboardInfo && !loadingStatus && (
        <Styles.Wrapper>
          {/* <h1>Dashboard Settings</h1> */}
          <Form className="settings-form">
            <Form.Item label="Dashboard Name" name="dashboardName">
              <Space.Compact
                style={{
                  width: '100%',
                }}
              >
                <Input
                  onChange={(e) => {
                    setDashboardName(e.target.value)
                  }}
                  value={dashboardName}
                />

                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={loadingStatus}
                  onClick={updateDashboardInfo}
                >
                  Rename
                </Button>
              </Space.Compact>
            </Form.Item>

            <Form.Item label="Invite New User" name="email">
              <Space.Compact style={{ width: '100%' }}>
                <Input
                  placeholder="Enter email address"
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                />

                <Button
                  type="primary"
                  onClick={() => {
                    addUser()
                  }}
                  disabled={loadingStatus}
                >
                  Add
                </Button>
              </Space.Compact>
            </Form.Item>
            <h1>Current Collaborators</h1>
            <List
              style={{
                fontColor: 'black',
                border: 'none',
              }}
              locale={{ emptyText: 'No collaborators yet' }}
              dataSource={props.collaborators || users}
              renderItem={(item, index) => (
                <List.Item>
                  <Avatar
                    style={{
                      verticalAlign: 'middle',
                    }}
                    size="large"
                  >
                    {item?.name ? item.name[0] : item?.displayName[0]}
                  </Avatar>
                  <List.Item>{item?.name || item?.displayName}</List.Item>
                  {user.uid === props.collaborators[0].uid && (
                    <Button
                      type="default"
                      danger
                      disabled={
                        loadingStatus || item.uid === props.collaborators[0].uid
                      }
                      onClick={() => removeUser(user)}
                    >
                      Remove
                    </Button>
                  )}
                </List.Item>
              )}
            />
            <h1>Invitations</h1>
            <List
              style={{
                fontColor: 'black',
                border: 'none',
              }}
              locale={{ emptyText: 'No invitations sent' }}
              dataSource={props.invites}
              renderItem={(item) => (
                <List.Item>
                  <List.Item>{item}</List.Item>
                  {user.uid === props.collaborators[0].uid && (
                    <Button
                      type="default"
                      danger
                      onClick={() => removeinvite(item)}
                      disabled={loadingStatus}
                    >
                      {' '}
                      Cancel Invitation
                    </Button>
                  )}
                </List.Item>
              )}
            />

            <Button
              type="default"
              danger
              onClick={() => {
                if (user.uid === props.collaborators[0]?.uid) {
                  // delete dashboard
                } else removeUser(user)
              }}
              disabled={loadingStatus}
            >
              {' '}
              {user.uid === props.collaborators[0]?.uid
                ? 'Delete Dashboard'
                : 'Leave Dashboard'}
            </Button>
          </Form>
        </Styles.Wrapper>
      )}
    </PageWrapper>
  )
}

const Styles = {
  Wrapper: styled.div`
    .settings-form {
      //   background-color: #f5f5f5;
      padding: 20px;
      //   border-radius: 10px;
      //   box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      max-width: 400px;
      margin: 0 auto;
    }
  `,
}

export default Settings
