import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Progress, Space } from 'antd'
import { Cascader, Form, Select, Input, InputNumber, Modal, Radio } from 'antd'
import { render } from 'react-dom'

const CreateMetricForm = ({ open, onCreate, onCancel, goals }) => {
  const [connectedGoal, setConnectedGoal] = useState(null)
  const [units, setUnits] = useState(null)
  const [metric, setMetric] = useState(null)
  const [dataSource, setDataSource] = useState(null)

  const [form] = Form.useForm()
  return (
    <Modal
      open={open}
      title={`Track a New Metric`}
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields()
            onCreate(values)
          })
          .catch((info) => {
            console.log('Validate Failed:', info)
          })
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: 'public',
        }}
      >
        <Form.Item
          name="goal"
          label="Which goal is this connected to?"
          rules={[
            {
              required: true,
              message: 'Please connect this metric to a goal!',
            },
          ]}
        >
          <Select onChange={(value) => setConnectedGoal(value)}>
            {goals?.map((goal) => {
              return (
                <Select.Option value={goal.description}>
                  {goal.description}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="objective"
          label="What metric are you tracking"
          rules={[
            {
              required: true,
              message: 'Please input your success metric!',
            },
          ]}
        >
          <Cascader
            options={[
              {
                value: 'marketing',
                label: 'marketing',
                children: [
                  {
                    value: 'impressions',
                    label: 'impressions',
                  },
                  {
                    value: 'clicks',
                    label: 'clicks',
                  },
                  {
                    value: 'conversions',
                    label: 'conversions',
                  },
                  {
                    value: 'leads',
                    label: 'leads',
                  },
                  {
                    value: 'subscribers',
                    label: 'subscribers',
                  },
                  {
                    value: 'followers',
                    label: 'followers',
                  },
                  {
                    value: 'visitors',
                    label: 'visitors',
                  },
                  {
                    value: 'backlinks',
                    label: 'backlinks',
                  },
                  {
                    value: 'posts',
                    label: 'posts',
                  },
                  {
                    value: 'partnerships',
                    label: 'partnerships',
                  },
                ],
              },
              {
                value: 'sales',
                label: 'sales',
                children: [
                  {
                    value: 'leads',
                    label: 'leads',
                  },
                  {
                    value: 'opportunities',
                    label: 'opportunities',
                  },
                  {
                    value: 'deals',
                    label: 'deals',
                  },
                  {
                    value: 'customers',
                    label: 'customers',
                  },
                  {
                    value: 'units',
                    label: 'units',
                  },
                  {
                    value: 'pre-sales',
                    label: 'pre-sales',
                  },
                  {
                    value: 'orders',
                    label: 'orders',
                  },
                ],
              },
              {
                value: 'finance',
                label: 'finance',
                children: [
                  {
                    value: 'K',
                    label: 'K',
                  },
                  {
                    value: 'M',
                    label: 'M',
                  },
                  {
                    value: 'B',
                    label: 'B',
                  },
                  {
                    value: 'monthly recurring revenue',
                    label: 'monthly recurring revenue',
                  },
                  {
                    value: 'annual recurring revenue',
                    label: 'annual recurring revenue',
                  },
                  {
                    value: 'revenue',
                    label: 'revenue',
                  },
                  {
                    value: 'profit',
                    label: 'profit',
                  },
                  {
                    value: 'cost savings',
                    label: 'cost savings',
                  },
                ],
              },
              {
                value: 'product',
                label: 'product',
                children: [
                  {
                    value: 'features',
                    label: 'features',
                  },
                  {
                    value: 'bugs',
                    label: 'bugs',
                  },
                  {
                    value: 'users',
                    label: 'users',
                  },
                  {
                    value: 'daily active users',
                    label: 'daily active users',
                  },
                  {
                    value: 'monthly active users',
                    label: 'monthly active users',
                  },
                  {
                    value: 'downloads',
                    label: 'downloads',
                  },
                  {
                    value: 'installs',
                    label: 'installs',
                  },
                  {
                    value: 'sessions',
                    label: 'sessions',
                  },
                ],
              },
              {
                value: 'operations',
                label: 'operations',
                children: [
                  {
                    value: 'employees',
                    label: 'employees',
                  },
                  {
                    value: 'sites',
                    label: 'sites',
                  },
                  {
                    value: 'hires',
                    label: 'hires',
                  },
                  {
                    value: 'roles',
                    label: 'roles',
                  },
                ],
              },
              {
                value: 'time',
                label: 'time',
                children: [
                  {
                    value: 'seconds',
                    label: 'seconds',
                  },
                  {
                    value: 'minutes',
                    label: 'minutes',
                  },
                  {
                    value: 'hours',
                    label: 'hours',
                  },
                  {
                    value: 'days',
                    label: 'days',
                  },
                  {
                    value: 'weeks',
                    label: 'weeks',
                  },
                ],
              },
              {
                value: 'percent',
                label: 'percent',
                children: [
                  {
                    value: 'percent',
                    label: 'percent',
                  },
                ],
              },
              {
                value: 'other',
                label: 'other',
                children: [
                  {
                    value: 'other',
                    label: 'other',
                  },
                ],
              },
            ]}
          />
        </Form.Item>
        {units === 'other' && <Input style={{ width: 100, marginRight: 8 }} />}
        <Form.Item
          name="quantity"
          label="What is your target value?"
          rules={[
            {
              required: true,
              message: 'Please input a quantity!',
            },
          ]}
        >
          <InputNumber min={1} />
        </Form.Item>

        <Form.Item name="dataSource" label="Where does this data live?">
          <Cascader
            options={[
              {
                value: 'marketing',
                label: 'marketing',
                children: [
                  {
                    value: 'Hubspot',
                    label: 'hubspot',
                  },
                  {
                    value: 'Salesforce',
                    label: 'salesforce',
                  },
                  {
                    value: 'Mailchimp',
                    label: 'mailchimp',
                  },
                  {
                    value: 'Twitter',
                    label: 'twitter',
                  },
                  {
                    value: 'TikTok',
                    label: 'tiktok',
                  },
                  {
                    value: 'Instagram',
                    label: 'instagram',
                  },
                  {
                    value: 'Facebook',
                    label: 'facebook',
                  },
                  {
                    value: 'Linkedin',
                    label: 'linkedin',
                  },
                  {
                    value: 'Google Analytics',
                    label: 'google analytics',
                  },
                  {
                    value: 'Google Ads',
                    label: 'google ads',
                  },
                  {
                    value: 'Google Sheets',
                    label: 'google sheets',
                  },
                  {
                    value: 'Excel',
                    label: 'excel',
                  },
                  {
                    value: 'Airtable',
                    label: 'airtable',
                  },
                  {
                    value: 'Wix',
                    label: 'wix',
                  },
                  {
                    value: 'SquareSpace',
                    label: 'squarespace',
                  },
                  {
                    value: 'Shopify',
                    label: 'shopify',
                  },
                ],
              },
              {
                value: 'sales',
                label: 'sales',
                children: [
                  {
                    value: 'Hubspot',
                    label: 'hubspot',
                  },
                  {
                    value: 'Salesforce',
                    label: 'salesforce',
                  },
                  {
                    value: 'Mailchimp',
                    label: 'mailchimp',
                  },
                  {
                    value: 'Google Sheets',
                    label: 'google sheets',
                  },
                  {
                    value: 'Excel',
                    label: 'excel',
                  },
                  {
                    value: 'Airtable',
                    label: 'airtable',
                  },
                  {
                    value: 'Paypal',
                    label: 'paypal',
                  },
                  {
                    value: 'Shopify',
                    label: 'shopify',
                  },
                  {
                    value: 'Square',
                    label: 'square',
                  },
                  {
                    value: 'Stripe',
                    label: 'stripe',
                  },
                  {
                    value: 'Quickbooks',
                    label: 'quickbooks',
                  },
                  {
                    value: 'Bill.com',
                    label: 'bill.com',
                  },
                ],
              },
              {
                value: 'finance',
                label: 'finance',
                children: [
                  {
                    value: 'Quickbooks',
                    label: 'Quickbooks',
                  },
                  {
                    value: 'Excel',
                    label: 'Excel',
                  },
                  {
                    value: 'Google Sheets',
                    label: 'Google Sheets',
                  },
                  {
                    value: 'Airtable',
                    label: 'airtable',
                  },
                  {
                    value: 'Stripe',
                    label: 'Stripe',
                  },
                  {
                    value: 'Shopify',
                    label: 'Shopify',
                  },
                  {
                    value: 'Square',
                    label: 'square',
                  },
                  {
                    value: 'Paypal',
                    label: 'paypal',
                  },
                  {
                    value: 'Bill.com',
                    label: 'bill.com',
                  },
                  {
                    value: 'Bank Account',
                    label: 'bank account',
                  },
                ],
              },
              {
                value: 'product',
                label: 'product',
                children: [
                  {
                    value: 'Jira',
                    label: 'jira',
                  },
                  {
                    value: 'Asana',
                    label: 'asana',
                  },
                  {
                    value: 'Trello',
                    label: 'trello',
                  },
                  {
                    value: 'Github',
                    label: 'github',
                  },
                  {
                    value: 'Google Sheets',
                    label: 'google sheets',
                  },
                  {
                    value: 'Excel',
                    label: 'excel',
                  },
                  {
                    value: 'Airtable',
                    label: 'airtable',
                  },
                  {
                    value: 'Google Analytics',
                    label: 'google analytics',
                  },
                  {
                    value: 'App Store',
                    label: 'app store',
                  },
                  {
                    value: 'Google Play',
                    label: 'google play',
                  },
                ],
              },
              {
                value: 'operations',
                label: 'operations',
                children: [
                  {
                    value: 'Google Sheets',
                    label: 'google sheets',
                  },
                  {
                    value: 'Excel',
                    label: 'excel',
                  },
                  {
                    value: 'Airtable',
                    label: 'airtable',
                  },
                ],
              },
              {
                value: 'other',
                label: 'other',
                children: [
                  {
                    value: 'nowhere',
                    label: 'nowhere',
                  },
                  {
                    value: 'other',
                    label: 'other',
                  },
                ],
              },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const Metrics = (props) => {
  const DATA = [
    {
      name: 'Metric 1',
      value: '30%',
      progress: 30,
    },
    {
      name: 'Metric 2',
      value: '100%',
      progress: 100,
    },
    {
      name: 'Metric 3',
      value: '70%',
      progress: 70,
    },
  ]

  const [metrics, setMetrics] = useState(null)
  const [isMobile, setIsMobile] = useState(false)
  const [openMetricForm, setOpenMetricForm] = useState(false)

  const handleAddMetric = (values) => {
    console.log('values are: ', values)
    let newMetric = {
      name: values.objective[1],
      value: values.quantity,
      progress: 0,
    }

    if (metrics) setMetrics([...metrics, newMetric])
    else setMetrics([newMetric])

    setOpenMetricForm(false)
  }

  const renderMetric = (data) => {
    console.log('rendering data', data)
    return (
      <div className="metric-item">
        <h1 className="metric-key">{`${data.name?.toUpperCase()}`}</h1>
        <Progress
          type="circle"
          percent={0}
          format={() => `${data.value}`}
          trailColor="gray"
        />
      </div>
    )
  }

  return (
    <Styles.Wrapper>
      {/* <CSSReset /> */}

      {isMobile ? (
        <MobileMetrics.Wrapper></MobileMetrics.Wrapper>
      ) : (
        <DesktopMetrics.Wrapper>
          <div className="section-header">
            <h1>KEY METRICS</h1>
            <button
              onClick={() => {
                setOpenMetricForm(true)
              }}
            >
              +
            </button>
          </div>
          <div className="metrics">
            {metrics?.map((item) => {
              return renderMetric(item)
            })}
          </div>
          <CreateMetricForm
            open={openMetricForm}
            onCreate={handleAddMetric}
            onCancel={() => {
              setOpenMetricForm(false)
            }}
            goals={props.goals}
          />
        </DesktopMetrics.Wrapper>
      )}
    </Styles.Wrapper>
  )
}

const Styles = {
  Wrapper: styled.div`
    padding-top: 1rem;
    width: 30%;
    flex-direction: column;
  `,
}

const DesktopMetrics = {
  Wrapper: styled.div`
    .section-header {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;

      button {
        margin-left: 1rem;
        border-radius: 50%;
        border: none;
        text-align: center;
      }
    }
    .metrics {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-color: #fff;

      .metric-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgb(49, 50, 51);
        border-radius: 8px;
        padding: 12px 16px;
        padding-inline-start: 12px;
        height: 150px;
        width: 150px;
        margin-bottom: 1rem;

        .ant-progress-text {
          color: #fff;
        }

        .metric-key {
          font-size: 1rem;
          margin-bottom: 0.5rem;
        }
        .metric-value {
          border-radius: 50%;
          background-color: #000;
          color: #fff;
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  `,
}

const MobileMetrics = {
  Wrapper: styled(DesktopMetrics.Wrapper)`
    position: fixed;
    width: 100vw;
    bottom: 0;

    justify-content: center;
  `,
}

export default Metrics
