import Home from './pages/Home'
import { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'
// import { VerifyPage } from './pages/Auth'
import { AuthForm } from './pages/Auth'
import { FourOFour } from './pages/404'
import Settings from './pages/Settings'
import Nav from './dashboard/dashboard-components/navigation'
import Dashboard from './dashboard'

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard/:dashboardId" element={<Dashboard />} />
          <Route path="/login" element={<AuthForm />} />
          <Route
            path="/join/:encodedURI/:inviteId/:dashboardId"
            element={<AuthForm />}
          />
          '
          <Route path="/settings" element={<Settings />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
