import styled from 'styled-components/macro'

export const PageWrapper = styled.div`
  // height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #000;
  // background-image: linear-gradient(
  //   180deg,
  //   #6350e8 0%,
  //   rgba(99, 80, 232, 0.72) 100%
  // );

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
`
