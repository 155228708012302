import styled from 'styled-components'

export const PageWrapper = styled.div`
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: linear-gradient(
    180deg,
    #6350e8 0%,
    rgba(99, 80, 232, 0.72) 100%
  );
  color: #fff;

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 3%;
    background-color: transparent;
    color: #444;
    position: relative;
  }

  .navbar .logo {
    width: 150px;
  }

  .navbar .logo img {
    width: 100%;
    cursor: pointer;
  }

  .navbar .nav-links {
    flex: 1;
    text-align: right;
  }

  .navbar .nav-links ul li {
    list-style: none;
    display: inline-block;
    padding: 8px 12px;
    position: relative;
  }

  .navbar .nav-links ul li a {
    color: #444;
    text-decoration: none;
    font-size: 13px;
    text-transform: uppercase;
  }

  .navbar .nav-links ul li::after {
    content: '';
    width: 0%;
    height: 2px;
    background: #444;
    display: block;
    margin: auto;
    transition: 0.5s;
  }

  .navbar .nav-links ul li:hover::after {
    width: 100%;
  }

  .navbar .burger {
    display: none;
    cursor: pointer;
  }

  .navbar .burger div {
    width: 25px;
    height: 3px;
    background-color: #444;
    margin: 5px;
    transition: all 0.3s ease;
  }

  @media (max-width: 768px) {
    .navbar .nav-links ul li {
      display: block;
    }

    .navbar .nav-links {
      position: absolute;
      background: #fff;
      height: 100vh;
      width: 200px;
      top: 0;
      right: -200px;
      text-align: left;
      z-index: 2;
      transition: 1s;
    }

    .navbar .nav-links.active {
      right: 0px;
    }

    .navbar .nav-links ul {
      padding: 30px;
    }

    .navbar .burger {
      display: block;
    }
  }

  .navbar .nav-links ul li {
    display: inline-block;
    padding: 8px 12px;
    position: relative;
  }
`

export const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 3%;
    margin-left: 3%;
    // position: absolute;
    // top: 168px;
    // left: 65px;

    @media (max-width: 425px) {
      font-size: 1.5rem;
      margin-top: 10%;
    }
  }

  p {
    font-size: 1.5rem;
    font-weight: 400;
    margin-left: 3%;
    margin-bottom: 1%;
  }

  .error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .error-msg {
      font-size: 1.5rem;
      font-weight: 700;
    }

    .error-img {
      margin-bottom: 10px;
      width: 50%;
    }
  }
`

export const Option = styled.div`
  width: 30%;
  min-height: 75px;
  font-size: 16px;
  font-weight: 700;
  background-color: rgba(217, 217, 217, 0.2);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${(props) => (props.hide ? 'hidden' : 'visible')};

  :hover {
    cursor: pointer;
    background-color: rgba(217, 217, 217, 0.4);
  }

  @media (max-width: 425px) {
    font-size: 0.8em;
  }
`
