import React, { useEffect, useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { List, Calendar, Activity } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../../firebaseConfig'
import { Avatar, Button, Menu, Dropdown, Popconfirm } from 'antd'
import { UserOutlined, SettingOutlined } from '@ant-design/icons'

function Nav(props) {
  const [windowDimension, setWindowDimension] = useState(null)

  const [user, loading, error] = useAuthState(auth)

  const [loginPage, setLoginPage] = useState(
    window.location.pathname === '/login',
  )

  useEffect(() => {
    setWindowDimension(window.innerWidth)
  }, [])

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const isMobile = windowDimension <= 640

  // useEffect(() => {
  //   if (!user) return (window.location.href = '/login')
  // }, [user])

  const onClick = ({ key }) => {
    switch (key) {
      case 'dashboards':
        window.location.href = '/'
        break
      case 'logout':
        auth.signOut()
        break
      case 'settings':
        props.onSettingsClicked()
      default:
        break
    }
  }
  const items = [
    {
      label: 'All Dashboards',
      key: 'dashboards',
    },
    {
      label: 'Settings',
      key: 'settings',
    },
    {
      label: 'Logout',
      key: 'logout',
    },
    // {
    //   label: '2nd menu item',
    //   key: '2',
    // },
    // {
    //   label: '3rd menu item',
    //   key: '3',
    // },
  ]

  return (
    <>
      {/* <CSSReset /> */}

      {windowDimension && isMobile && !loginPage ? (
        <MobileNavbar.Wrapper>
          <MobileNavbar.Items>
            <MobileNavbar.Item
              onClick={() => {
                props.onSelect('goals')
              }}
            >
              <MobileNavbar.Icon>
                <List size={16} />
              </MobileNavbar.Icon>
              Goals
            </MobileNavbar.Item>
            <MobileNavbar.Item
              onClick={() => {
                props.onSelect('overview')
              }}
            >
              <MobileNavbar.Icon>
                <Calendar size={16} />
              </MobileNavbar.Icon>
              Overview
            </MobileNavbar.Item>
            <MobileNavbar.Item
              onClick={() => {
                props.onSelect('metrics')
              }}
            >
              <MobileNavbar.Icon>
                <Activity size={16} />
              </MobileNavbar.Icon>
              Metrics
            </MobileNavbar.Item>
          </MobileNavbar.Items>
        </MobileNavbar.Wrapper>
      ) : (
        <Navbar.Wrapper>
          <Navbar.Logo>
            <img src={'/floreo.png'} />
          </Navbar.Logo>
          <Navbar.Items>
            {/* <h1>Ganance</h1> */}
            {/* <Navbar.Item>Goals</Navbar.Item>
            <Navbar.Item>Blog</Navbar.Item>
            <Navbar.Item>About</Navbar.Item> */}
          </Navbar.Items>

          <Navbar.Action>
            {user && !loading && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {props.name && (
                  <div
                    style={{
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                    }}
                  >
                    {props.name}
                  </div>
                )}
                <Dropdown
                  menu={{
                    items,
                    onClick,
                  }}
                >
                  <Avatar
                    style={{
                      backgroundColor: 'rgb(49, 50, 51)',
                    }}
                    icon={
                      window.location.pathname === '/' ? (
                        <SettingOutlined />
                      ) : (
                        <>
                          <UserOutlined />
                        </>
                      )
                    }
                  />
                </Dropdown>
              </div>
            )}

            {!user && !loading && (
              <Button
                type="primary"
                onClick={() => {
                  window.location.href = '/login'
                }}
              >
                Login
              </Button>
            )}
          </Navbar.Action>
        </Navbar.Wrapper>
      )}
    </>
  )
}

const Styles = {
  Wrapper: styled.main`
    display: flex;
    background-color: purple;
    height: 100vh;
  `,
}

const Navbar = {
  Wrapper: styled.nav`
    flex: 1;

    align-self: flex-start;

    padding: 1rem 3rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    // background-color: blue;
    color: white;
  `,
  Logo: styled.h1`
    // border: 1px solid gray;
    padding: 0.5rem 1rem;

    img {
      width: 100px;
    }
  `,
  Items: styled.ul`
    display: flex;
    list-style: none;
  `,
  Item: styled.li`
    padding: 0 1rem;
    cursor: pointer;
  `,
  Action: styled.div``,
}

const MobileNavbar = {
  Wrapper: styled(Navbar.Wrapper)`
    position: fixed;
    width: 100vw;
    bottom: 0;

    justify-content: center;
  `,
  Items: styled(Navbar.Items)`
    flex: 1;
    padding: 0 2rem;

    justify-content: space-around;
  `,
  Item: styled(Navbar.Item)`
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 1.2rem;
  `,
  Icon: styled.span``,
}

const CSSReset = createGlobalStyle`
  *,
  *::before, 
  *::after {
    margin: 0; 
    padding: 0;
    box-sizing: inherit;
  }

  html {
    font-size: 62.5%; /*1rem = 10px*/
    box-sizing: border-box;      
  }  

  body {
    font-size: 1.4rem;
    font-family: sans-serif;
  }
`

export default Nav
