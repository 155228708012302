import { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
// import { List, Calendar, Activity } from 'react-feather'
import DataTable from 'react-data-table-component'
import { GoalTable } from '../ResizableTable'
import {
  SettingOutlined,
  ProfileFilled,
  UserOutlined,
  UserAddOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons'
import {
  Collapse,
  Tag,
  Avatar,
  Progress,
  theme,
  Select,
  Button,
  Checkbox,
  Popconfirm,
  Space,
  message,
  Popover,
} from 'antd'
import {
  Cascader,
  Form,
  DatePicker,
  Input,
  InputNumber,
  Modal,
  Radio,
  List,
} from 'antd'
import { set } from 'date-fns'
import { DashboardAPI } from '../../../helpers/API-consts'
import Item from 'antd/es/list/Item'

const CreateGoalForm = ({ open, onCreate, onCancel }) => {
  const [value, setValue] = useState(1)
  const [units, setUnits] = useState('unit')

  const [form] = Form.useForm()
  return (
    <Modal
      open={open}
      title="Let's Create A New Goal!"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields()
            onCreate(values)
          })
          .catch((info) => {
            console.log('Validate Failed:', info)
          })
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: 'public',
        }}
      >
        <Form.Item name="description" label="Description">
          <Input type="textarea" />
        </Form.Item>
      </Form>
    </Modal>
  )
}
const UpdateGoalForm = ({ open, onCreate, onCancel, goal, handleDelete }) => {
  const [value, setValue] = useState(1)
  const [units, setUnits] = useState('unit')

  const confirmDelete = (e) => {
    console.log(e)
    // message.success('Click on Yes')
    handleDelete(goal)
  }
  const cancelDelete = (e) => {
    console.log(e)
    // message.error('Click on No')
  }

  const [form] = Form.useForm()
  return (
    <Modal
      open={open}
      title="Edit this goal"
      okText="Update"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields()
            onCreate(values, goal)
          })
          .catch((info) => {
            console.log('Validate Failed:', info)
          })
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: 'public',
        }}
      >
        <Form.Item name="description" label="Rename">
          <Input type="textarea" placeholder={goal?.description} />
        </Form.Item>
        <Form.Item name="delete" label="Delete this goal">
          <Popconfirm
            title="Delete this goal?"
            description="This action cannot be undone. All milestones and connected tasks for this goal will be deleted."
            onConfirm={confirmDelete}
            onCancel={cancelDelete}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>Delete</Button>
          </Popconfirm>
        </Form.Item>
      </Form>
    </Modal>
  )
}
const CreateMilestoneForm = ({
  open,
  onCreate,
  onCancel,
  goal,
  collaborators,
}) => {
  const [owner, setOwner] = useState(null)
  const [due, setDue] = useState(null)
  const [progress, setProgress] = useState(null)
  const [milestone, setMilestone] = useState(null)
  const [morale, setMorale] = useState(null)

  const [form] = Form.useForm()
  return (
    <Modal
      open={open}
      title={`Create New Milestone`}
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields()
            onCreate(values, goal)
          })
          .catch((info) => {
            console.log('Validate Failed:', info)
          })
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: 'public',
        }}
      >
        <p style={{ marginBottom: '1rem' }}>
          What needs to be achieved to{' '}
          <span
            style={{
              textDecoration: 'underline',
              textDecorationColor: 'purple',
            }}
          >
            {goal?.toLowerCase()}
          </span>
        </p>
        <Form.Item name="milestone" label="Milestone">
          <Input type="textarea" />
        </Form.Item>
        <Form.Item name="due" label="Due">
          <DatePicker
            format={'MMM DD'}
            onChange={(value, string) => setDue(string)}
          />
        </Form.Item>
        <Form.Item name="owner" label="Owner">
          <Select onChange={(value) => setOwner(value)}>
            {/* <Select.Option value="Cam">Cam</Select.Option>
            <Select.Option value="Naomi">Naomi</Select.Option>
            <Select.Option value="Alex">Alex</Select.Option> */}
            {collaborators?.map((collaborator) => {
              return (
                <Select.Option
                  value={collaborator?.name || collaborator?.displayName}
                >
                  {collaborator?.name || collaborator?.displayName}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

const UpdateMilestoneForm = ({
  open,
  onCreate,
  onCancel,
  milestone,
  tasks,
  deleteMilestone,
  collaborators,
  handleTaskChange,
}) => {
  const [description, setDescription] = useState(null)
  const [owner, setOwner] = useState(null)
  const [due, setDue] = useState(null)
  const [editingTask, setEditingTask] = useState(null)
  const [editedTaskList, setEditedTaskList] = useState(null)
  const [editedTaskDesc, setEditedTaskDesc] = useState(null)
  const [newTaskDesc, setNewTaskDesc] = useState('')
  const [newTask, setNewTask] = useState(null)
  // let connectedMilestone = goal?.milestones?.find((_milestone) => {
  //   console.log(goal, _milestone, milestone)
  //   return _milestone?.description === milestone?.description
  // })

  // console.log(connectedMilestone.tasks)

  // const [tasks, setTasks] = useState(milestone.tasks)

  const [form] = Form.useForm()

  // useEffect(() => {
  //   if (connectedMilestone && connectedMilestone.tasks) {
  //     console.log('should set tasks', connectedMilestone.tasks)
  //     setTasks(connectedMilestone.tasks)
  //   }
  // }, [])

  // useEffect(() => {
  //   console.log('active milestone updated')
  //   if (milestone && milestone.tasks && milestone.tasks !== tasks) {
  //     console.log('received new tasks')
  //     setTasks(milestone.tasks)
  //   }
  // }, [milestone])

  const changeOwner = (item, index) => {
    const handleChange = (value) => {
      console.log('swapping owner')
      // create a new task
      // add it to the milestone
      // update the milestone
      // update the goal
      // update the database
      // update the UI
      let newTask = { ...item }
      newTask.owner = value

      let prevTaskList = null
      let newTaskList = null

      if (!editedTaskList) {
        prevTaskList = milestone?.tasks
        newTaskList = [...prevTaskList]
        newTaskList[index] = newTask
      } else {
        prevTaskList = editedTaskList
        newTaskList = [...prevTaskList]
        newTaskList[index] = newTask
      }

      setEditedTaskList(newTaskList)
      // createTask(e.target.value, milestone, goal)
    }

    return (
      <Select autoFocus onChange={handleChange} style={{ width: '100%' }}>
        {/* <Select.Option value="Cam">Cam</Select.Option>
            <Select.Option value="Naomi">Naomi</Select.Option>
            <Select.Option value="Alex">Alex</Select.Option> */}
        {collaborators?.map((collaborator) => {
          return (
            <Select.Option
              value={collaborator?.name || collaborator?.displayName}
            >
              {collaborator?.name || collaborator?.displayName}
            </Select.Option>
          )
        })}
      </Select>
    )
  }

  const changeDueDate = (item, index) => {
    const handleChange = (date, string) => {
      console.log('swapping date')
      // create a new task
      // add it to the milestone
      // update the milestone
      // update the goal
      // update the database
      // update the UI
      let newTask = { ...item }
      newTask.due = string

      let prevTaskList = null
      let newTaskList = null

      if (!editedTaskList) {
        prevTaskList = milestone?.tasks
        newTaskList = [...prevTaskList]
        newTaskList[index] = newTask
      } else {
        prevTaskList = editedTaskList
        newTaskList = [...prevTaskList]
        newTaskList[index] = newTask
      }

      setEditedTaskList(newTaskList)
      // createTask(e.target.value, milestone, goal)
    }
    return <DatePicker autoFocus format={'MMM DD'} onChange={handleChange} />
  }

  const removeTask = (item, index) => {
    console.log('swapping owner')
    // create a new task
    // add it to the milestone
    // update the milestone
    // update the goal
    // update the database
    // update the UI

    let prevTaskList = null
    let newTaskList = null

    if (!editedTaskList) {
      prevTaskList = milestone?.tasks
      newTaskList = [...prevTaskList]
      newTaskList.splice(index, 1)
    } else {
      prevTaskList = editedTaskList
      newTaskList = [...prevTaskList]
      newTaskList.splice(index, 1)
    }

    setEditedTaskList(newTaskList)
    // createTask(e.target.value, milestone, goal)
  }

  return (
    <Modal
      open={open}
      title={`${milestone?.description}`}
      okText="Update"
      cancelText="Cancel"
      onCancel={() => {
        onCancel()
        setEditingTask(null)
        setNewTaskDesc(null)
        setEditedTaskDesc(null)
        setEditedTaskList(null)
        setDue(null)
        setOwner(null)
        setDescription(null)
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields()

            let newMilestone = { ...milestone }
            if (description) newMilestone.description = description
            if (owner) newMilestone.owner = owner
            if (due) newMilestone.due = due
            if (editedTaskList) newMilestone.tasks = editedTaskList

            onCreate(newMilestone)
            setEditingTask(null)
            setNewTaskDesc(null)
            setEditedTaskDesc(null)
            setEditedTaskList(null)
            setDue(null)
            setOwner(null)
            setDescription(null)
          })
          .catch((info) => {
            console.log('Validate Failed:', info)
          })
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        title="Update Milestone"
        onClick={(e) => {
          console.log(e.target.tagName, 'clicked')
          let acceptableTags = ['INPUT', 'H4']
          if (
            editingTask &&
            !acceptableTags.includes(e.target.tagName) &&
            e.target.type !== 'checkbox'
          )
            setEditingTask(null)
        }}
      >
        <Form.Item name="description" label="Milestone Description">
          <Input
            type="textarea"
            placeholder={milestone?.description}
            onChange={(e) => {
              setDescription(e.target.value)
            }}
          />
        </Form.Item>

        <Form.Item
          name="due"
          label="Due"
          style={{
            display: 'inline-block',
            width: 'calc(50% - 8px)',
          }}
        >
          <DatePicker
            onChange={(value, string) => setDue(string)}
            format={'MMM DD'}
            placeholder={milestone?.due}
          />
        </Form.Item>

        <Form.Item
          name="owner"
          label="Owner"
          style={{
            display: 'inline-block',
            width: 'calc(30% - 8px)',
            margin: '0 8px',
          }}
        >
          <Select
            defaultValue={milestone?.owner}
            onChange={(value) => setOwner(value)}
          >
            {/* <Select.Option value="Cam">Cam</Select.Option>
            <Select.Option value="Naomi">Naomi</Select.Option>
            <Select.Option value="Alex">Alex</Select.Option> */}
            {collaborators?.map((collaborator) => {
              return (
                <Select.Option
                  value={collaborator?.name || collaborator?.displayName}
                >
                  {collaborator?.name || collaborator?.displayName}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        <div> Milestone Tasks </div>
        {console.log(tasks, 'prop tasks')}
        <List
          itemLayout="horizontal"
          dataSource={editedTaskList || milestone?.tasks}
          renderItem={(item, index) => (
            <List.Item
              onClick={(e) => {
                if (e.target.type !== 'checkbox') {
                  console.log(e.target)
                  setEditingTask(item)
                }
              }}
            >
              {console.log('item', item)}
              <Checkbox
                // onClick={(e) => {
                //   console.log('item clicked', e.target.checked)
                //   handleTaskChange(item, !e.target.checked, index, milestone)
                //   // alert('clicked')
                // }}
                onChange={(e) => {
                  handleTaskChange(item, e.target.checked, index, milestone)
                }}
                // onChange={(e) => {
                //   console.log('swapping task open')
                //   // create a new task
                //   // add it to the milestone
                //   // update the milestone
                //   // update the goal
                //   // update the database
                //   // update the UI
                //   let newTask = { ...item }
                //   newTask.open = !e.target.checked

                //   let prevTaskList = null
                //   let newTaskList = null

                //   if (!editedTaskList) {
                //     prevTaskList = milestone?.tasks
                //     newTaskList = [...prevTaskList]
                //     newTaskList[index] = newTask
                //   } else {
                //     prevTaskList = editedTaskList
                //     newTaskList = [...prevTaskList]
                //     newTaskList[index] = newTask
                //   }

                //   setEditedTaskList(newTaskList)
                //   // createTask(e.target.value, milestone, goal)
                // }}
                checked={!item.open}
                style={{ marginRight: '1rem' }}
              />

              {editingTask !== item ? (
                <List.Item.Meta
                  // avatar={item.owner ? <Avatar>{item.owner[0]}</Avatar> : null}
                  title={item.description}
                  // description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                />
              ) : (
                <>
                  <List.Item.Meta
                  // avatar={
                  //   item.owner ? (
                  //     <Avatar onClick={changeOwner}>{item.owner[0]}</Avatar>
                  //   ) : (
                  //     <Avatar icon={<UserOutlined />} onClick={changeOwner} />
                  //   )
                  // }
                  />
                  <Input
                    type="textarea"
                    defaultValue={item.description}
                    onChange={(e) => setEditedTaskDesc(e.target.value)}
                    onKeyDown={(e) => {
                      // check if 'enter' key was pressed
                      console.log(e.key)
                      if (e.key === 'Enter') {
                        console.log('enter key pressed')

                        let newTask = {
                          description: e.target.value,
                          owner: item.owner,
                          open: item.open,
                          due: item.due,
                        }

                        let prevTaskList = null
                        let newTaskList = null

                        if (!editedTaskList) {
                          prevTaskList = milestone?.tasks
                          newTaskList = [...prevTaskList]
                          newTaskList[index] = newTask
                        } else {
                          prevTaskList = editedTaskList
                          newTaskList = [...prevTaskList]
                          newTaskList[index] = newTask
                        }

                        setEditedTaskList(newTaskList)

                        // create a new task
                        // add it to the milestone
                        // update the milestone
                        // update the goal
                        // update the database
                        // update the UI
                        // updateTask(e.target.value, index, milestone, goal)
                        setEditingTask(null)
                      }
                    }}
                  />
                  {/* <CloseOutlined
                    onClick={(e) => {
                      console.log('should stop changing')
                      setEditingTask(null)
                    }}
                    style={{ color: 'red' }}
                  /> */}
                  {/* <CheckOutlined onClick={()=>{
                    setEditingTask(null)
                  }} /> */}
                </>
              )}
              <Space>
                <Popover
                  content={changeDueDate(item, index)}
                  title={'Choose a due date for this task'}
                  trigger="click"
                >
                  {item.due ? (
                    <Tag color="green">{item.due}</Tag>
                  ) : (
                    <ClockCircleOutlined />
                  )}
                </Popover>
                <Popover
                  content={changeOwner(item, index)}
                  title={'Choose an owner for this task'}
                  trigger="click"
                >
                  {item.owner ? (
                    <Avatar>{item?.owner[0]}</Avatar>
                  ) : (
                    <UserAddOutlined />
                  )}
                </Popover>
                <Popconfirm
                  title="Are you sure you want to delete this task?"
                  description="This action cannot be undone. This task will also be removed from any connected sprint."
                  onConfirm={() => {
                    removeTask(item, index)
                  }}
                >
                  <DeleteOutlined />
                </Popconfirm>
              </Space>
            </List.Item>
          )}
        />

        <Form.Item name="taskDescription" label="Create a new task">
          <Space.Compact>
            <Input
              type="textarea"
              style={{
                width: '400px',
              }}
              value={newTaskDesc}
              onChange={(e) => {
                setNewTaskDesc(e.target.value)
                setNewTask({ description: e.target.value })
              }}
              onKeyDown={(e) => {
                // check if 'enter' key was pressed

                // if (e.key === 'Enter') alert(newTask.description)
                if (e.key === 'Enter') {
                  console.log('enter key pressed')
                  if (newTaskDesc === '' || !newTask) return

                  // create a new task
                  // add it to the milestone
                  // update the milestone
                  // update the goal
                  // update the database
                  // update the UI
                  let _newTask = newTask
                  _newTask.open = true

                  // {
                  //   description: e.target.value,
                  //   // owner: item.owner,
                  //   open: true,
                  //   // due: item.due,
                  // }

                  let prevTaskList = null
                  let newTaskList = null

                  if (!editedTaskList) {
                    prevTaskList = milestone?.tasks
                    newTaskList = [...prevTaskList]
                    newTaskList.push(_newTask)
                  } else {
                    prevTaskList = editedTaskList
                    newTaskList = [...prevTaskList]
                    newTaskList.push(_newTask)
                  }

                  setEditedTaskList(newTaskList)
                  setNewTaskDesc('')
                  // createTask(e.target.value, milestone, goal)
                }
              }}
            />
            <Button
              type="primary"
              onClick={() => {
                if (newTaskDesc === '') return
                let _newTask = newTask

                _newTask.open = true

                let prevTaskList = null
                let newTaskList = null

                if (!editedTaskList) {
                  prevTaskList = milestone?.tasks
                  newTaskList = [...prevTaskList]
                  newTaskList.push(_newTask)
                } else {
                  prevTaskList = editedTaskList
                  newTaskList = [...prevTaskList]
                  newTaskList.push(_newTask)
                }

                setEditedTaskList(newTaskList)
                setNewTaskDesc('')
              }}
              // onClick={() => alert(newTask.description)}
            >
              Add
            </Button>
          </Space.Compact>
        </Form.Item>

        <Form.Item name="deleteMilestone" label="Caution ⚠️">
          <Popconfirm
            title="Are you sure you want to delete this milestone?"
            description="This action cannot be undone. All tasks will be deleted."
            onConfirm={() => {
              deleteMilestone(milestone)
            }}
          >
            <Button danger>Delete Milestone</Button>
          </Popconfirm>
        </Form.Item>
      </Form>
    </Modal>
  )
}

const COLUMNS = [
  { title: 'Owner', width: 50, dataIndex: 'owner' },
  { title: 'Due', width: 50, dataIndex: 'due' },
  { title: 'Milestone', width: 300, dataIndex: 'milestone' },
  { title: 'Progress', width: 150, dataIndex: 'progress' },
  // { title: 'Status', width: 50 },
  // { title: 'Change', width: 50 },
  // { title: 'Morale', width: 50, dataIndex: 'morale' },
  { title: 'Tasks', width: 50, dataIndex: 'tasks' },
]

function Goals(props) {
  const [windowDimension, setWindowDimension] = useState(null)
  const [goals, setGoals] = useState(props.goals)
  const [goalData, setGoalData] = useState([])
  const [newGoal, setNewGoal] = useState(null)
  const [activeGoal, setActiveGoal] = useState(null)
  const [openGoalForm, setOpenGoalForm] = useState(false)
  const [openMilestoneForm, setOpenMilestoneForm] = useState(false)
  const [openUpdateGoalForm, setOpenUpdateGoalForm] = useState(false)
  const [openTasksForm, setOpenTasksForm] = useState(false)
  const [activeMilestone, setActiveMilestone] = useState(null)
  const [loading, setLoading] = useState(false)
  const [goalToEdit, setGoalToEdit] = useState(null)
  const [oldActiveIndex, setOldActiveIndex] = useState(null)

  console.log('goals props', props)

  // useEffect(() => {
  //   let newItems = []
  //   GOALS.forEach((goal, index) => {
  //     let newGoalItem = {
  //       key: index,
  //       label: goal.title,
  //       children: (
  //         <ResizableTable
  //           goal={goal.title}
  //           columns={COLUMNS}
  //           data={goal.milestones}
  //           onAdd={() => {
  //             setActiveGoal(goal.title)
  //             setOpenMilestoneForm(true)
  //           }}
  //         />
  //       ),
  //     }
  //     newItems.push(newGoalItem)
  //   })
  //   setGoals(newItems)

  // }, [goalData])

  useEffect(() => {
    if (props.showSprintTask) {
      setOpenTasksForm(false)
      setActiveMilestone(props.showSprintTask.milestone)
      setOpenTasksForm(true)
    }
  }, [props.showSprintTask])

  const generateSettingIcon = (_goal) => {
    return (
      <SettingOutlined
        style={{ color: 'white', cursor: 'pointer', marginLeft: '1rem' }}
        onClick={(event) => {
          // If you don't want click extra trigger collapse, you can prevent this:
          let newGoalToEdit = props.goals.find((oldGoal) => {
            return _goal == oldGoal
          })
          console.log('goal to edit', newGoalToEdit)
          setGoalToEdit(newGoalToEdit)

          event.stopPropagation()
        }}
      />
    )
  }

  const updateGoals = async (newGoals) => {
    props.handleLoading()
    console.log('updating goals for dashboard', props.dashboardId)
    try {
      let response = await fetch(DashboardAPI + 'updateDashboard', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          dashboardId: props.dashboardId,
          goals: newGoals,
        }),
      })
      if (response.status === 200) console.log('updated goals in firebase')
    } catch (err) {
      console.log(err)
    }
  }

  const onCreate = async (values) => {
    console.log('Received values of form: ', values)

    let newGoal = {
      description: values.description,
      milestones: [],
    }

    // setGoals([...goals, newGoal])
    await updateGoals([...props.goals, newGoal])

    // let newGoalItem = {
    //   key: goals.length + 1,
    //   label: values.description,
    //   children: (
    //     <ResizableTable
    //       goal={values.description}
    //       columns={COLUMNS}
    //       data={[]}
    //       onAdd={() => {
    //         setActiveGoal(values.description)
    //         setOpenMilestoneForm(true)
    //       }}
    //     />
    //   ),
    // }
    // let newItems = [...goals, newGoalItem]
    // setGoals(newItems)

    setOpenGoalForm(false)
  }

  const updateGoalDescription = async (values, goal) => {
    let newGoals = props.goals

    let goalIndex = newGoals.findIndex((oldGoal) => {
      return oldGoal.description === goal.description
    })

    if (goalIndex !== -1) {
      newGoals[goalIndex].description = values.description
    }

    await updateGoals(newGoals)

    setOpenUpdateGoalForm(false)
  }

  const deleteGoal = async (goal) => {
    let newGoals = props.goals.filter((oldGoal) => {
      return oldGoal.description !== goal.description
    })

    await updateGoals(newGoals)

    setOpenUpdateGoalForm(false)
  }
  // const updateGoal = (data) => {
  //   // find the active goal in the goals array
  //   // update the goal with the new data
  //   // setGoals(newGoals)

  //   let newGoals = goals.map((goal) => {
  //     if (goal.label === activeGoal) {
  //       goal.children = (
  //         <ResizableTable
  //           goal={activeGoal}
  //           columns={COLUMNS}
  //           data={[]}
  //           onAdd={() => {
  //             setActiveGoal(values.description)
  //             setOpenMilestoneForm(true)
  //           }}
  //         />
  //       )
  //     }
  //     return goal
  //   }
  //   )
  //   setGoals(newGoals)
  // }

  const onCreateMilestone = async (values, connectedGoal) => {
    console.log('Received values of form: ', values)

    let newMilestone = {
      description: values.milestone,
      owner: values.owner,
      due: values.due,
      tasks: [],
      progress: 0,
    }

    let newGoals = props.goals.map((goal) => {
      console.log('goal', goal, 'connectedGoal', connectedGoal)
      if (goal.description === connectedGoal) {
        goal.milestones.push(newMilestone)
      }
      return goal
    })

    // setGoals(newGoals)
    await updateGoals(newGoals)
    console.log('should have set goals', newGoals)

    setOpenMilestoneForm(false)
  }

  const onUpdateMilestone = async (data) => {
    // let newGoals = props.goals.map((goal) => {
    //   console.log('goal', goal, 'activeGoal', activeGoal)
    //   if (goal === activeGoal) {
    //     goal.milestones.map((milestone) => {
    //       console.log('767', milestone, activeMilestone)
    //       if (milestone.description === activeMilestone.description) {
    //         console.log('found milestone to update')
    //         milestone = { ...data }
    //         setActiveMilestone(milestone)
    //       }
    //       return milestone
    //     })
    //   }
    //   return goal
    // })
    console.log(
      'updating milestone',
      data,
      activeGoal,
      activeMilestone.description,
    )
    let newGoals = null

    let goalIndex = null

    if (activeGoal) {
      goalIndex = props.goals.findIndex((goal) => {
        return goal.description === activeGoal.description
      })
    } else {
      let _activeGoal = props.goals.find((goal, index) => {
        if (goal.milestones.includes(activeMilestone)) {
          goalIndex = index
          return true
        }
      })
    }
    // if (!activeGoal) {
    //   _activeGoal = props.goals.find((goal, index) => {
    //     console.log(goal)
    //     let _milestone = goal.milestones.find((milestone) => {
    //       console.log(milestone.description, activeMilestone.description)
    //       return milestone.description == activeMilestone.description
    //     })
    //     if (_milestone) goalIndex = index
    //     return _milestone ? true : false
    //   })
    // }

    // let goalIndex = props.goals.findIndex((goal) => {
    //   return goal === _activeGoal
    // })

    console.log(
      'found goal',
      goalIndex,
      props.goals[goalIndex],
      activeMilestone,
    )

    if (goalIndex !== -1) {
      let milestoneIndex = props.goals[goalIndex].milestones.findIndex(
        (milestone) => milestone.description == activeMilestone.description,
      )

      console.log(
        'found milestone',
        milestoneIndex,
        props.goals[goalIndex].milestones[milestoneIndex],
      )

      if (milestoneIndex === -1) {
        setActiveMilestone(null)
        setActiveGoal(null)
        setOldActiveIndex(null)
        setOpenTasksForm(false)
      }

      if (milestoneIndex !== -1) {
        newGoals = props.goals
        newGoals[goalIndex].milestones[milestoneIndex] = { ...data }

        await updateGoals(newGoals)
        console.log('should have updated goals with new milestone', newGoals)

        setOldActiveIndex([goalIndex, milestoneIndex])
        setActiveMilestone(null)
        setOpenTasksForm(false)
      }
    }

    // setGoals(newGoals)
  }

  const onDeleteMilestone = async (milestone) => {
    console.log('deleting milestone', activeMilestone.description)
    let newGoals = null

    let goalIndex = props.goals.findIndex((goal) => {
      return goal.description === activeGoal.description
    })
    // if (!activeGoal) {
    //   _activeGoal = props.goals.find((goal, index) => {
    //     console.log(goal)
    //     let _milestone = goal.milestones.find((milestone) => {
    //       console.log(milestone.description, activeMilestone.description)
    //       return milestone.description == activeMilestone.description
    //     })
    //     if (_milestone) goalIndex = index
    //     return _milestone ? true : false
    //   })
    // }

    // let goalIndex = props.goals.findIndex((goal) => {
    //   return goal === _activeGoal
    // })

    console.log(
      'found goal',
      goalIndex,
      props.goals[goalIndex],
      activeMilestone,
    )

    if (goalIndex !== -1) {
      let milestoneIndex = props.goals[goalIndex].milestones.findIndex(
        (milestone) => milestone.description == activeMilestone.description,
      )

      console.log(
        'found milestone',
        milestoneIndex,
        props.goals[goalIndex].milestones[milestoneIndex],
      )

      if (milestoneIndex !== -1) {
        newGoals = props.goals
        newGoals[goalIndex].milestones.splice(milestoneIndex, 1)

        await updateGoals(newGoals)
        console.log('should have deleted milestone', newGoals)

        // setActiveMilestone(null)
      }
    }

    // setGoals(newGoals)
  }
  useEffect(() => {
    setWindowDimension(window.innerWidth)
  }, [])

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const isMobile = windowDimension <= 425

  useEffect(() => {
    if (activeMilestone) {
      setOpenTasksForm(true)
    } else {
      setOpenTasksForm(false)
    }
  }, [activeMilestone])

  const { token } = theme.useToken()

  // useEffect(() => {
  //   if (newGoal) {
  //     let newGoalItem = {
  //       key: goals.length + 1,
  //       label: newGoal.goalTitle,
  //       children: <ResizableTable columns={COLUMNS} onAdd={()=>{
  //         setActiveGoal(values.description)
  //         setOpenMilestoneForm(true)
  //       }} data={[]} />,
  //       style: { fontColor: 'white' },
  //     }
  //     let newItems = [...goals, newGoalItem]
  //     setGoals(newItems)
  //   }
  // }, [newGoal])

  useEffect(() => {
    if (oldActiveIndex) {
      setActiveGoal(props.goals[oldActiveIndex[0]])
      setActiveMilestone(
        props.goals[oldActiveIndex[0]].milestones[oldActiveIndex[1]],
      )
      setOpenTasksForm(true)
      setOldActiveIndex(null)
    }
  }, [props.goals])

  const renderGoalItems = () => {
    console.log('rendering goal items', props.goals)

    const renderMilestones = (milestones) => {
      console.log('rendering milestones', milestones)
      return milestones.map((milestone, index) => {
        let progress = milestone.tasks.filter((task) => task.open == false)
          .length
        console.log('milestone progress', progress, milestone.tasks.length)
        let percentComplete = Math.floor(
          (progress / milestone.tasks.length) * 100,
        )
        return {
          key: index + 1,
          milestone: (
            <div
              style={{
                cursor: 'pointer',
                fontColor: 'blue',
                textDecoration: 'underline',
              }}
              onClick={() => {
                let connectedGoal = props.goals.find(
                  (goal) => goal.description === activeGoal?.description,
                )
                let connectedMilestone = connectedGoal?.milestones?.find(
                  (_milestone) =>
                    milestone?.description === _milestone?.description,
                )
                setActiveMilestone(connectedMilestone)
              }}
            >
              {milestone.description}
            </div>
          ),
          owner: milestone.owner,
          // due: milestone.due,
          progress: <Progress percent={percentComplete} size="small" />,
          status: milestone.status,
          change: milestone.change,
          morale: milestone.morale,
          tasks: (
            <ProfileFilled
              style={{ color: '#1677ff' }}
              onClick={() => {
                let connectedGoal = props.goals.find(
                  (goal) => goal.description === activeGoal?.description,
                )
                let connectedMilestone = connectedGoal?.milestones?.find(
                  (_milestone) =>
                    milestone?.description === _milestone?.description,
                )
                setActiveMilestone(connectedMilestone)
              }}
            />
          ),
          // tasks: (
          //   <Button
          //     type="primary"
          //     icon={<ProfileOutlined />}
          //     onClick={() => {
          //       console.log('clicked')
          //     }}
          //   >
          //     Tasks
          //   </Button>
          // ),
        }
      })
    }

    return props.goals.map((goal, index) => {
      return {
        key: index,
        label: goal.description,
        children: (
          <GoalTable
            goal={goal}
            goalIndex={index}
            columns={COLUMNS}
            data={renderMilestones(goal.milestones)}
            onAdd={() => {
              setActiveGoal(goal)
              console.log('active goal', goal)
              setOpenMilestoneForm(true)
            }}
          />
        ),
        extra: generateSettingIcon(goal),
      }
    })
  }

  return (
    <Styles.Wrapper>
      {/* <CSSReset /> */}

      {isMobile ? (
        <MobileGoals.Wrapper></MobileGoals.Wrapper>
      ) : (
        <DesktopGoals.Wrapper>
          <div className="section-header">
            <h1>GOALS & MILESTONES</h1>
            <button
              onClick={() => {
                // window.confirm(`👋🏿 Hey friend! We're here to help you succeed. To get started, let's set a clear goal.`)
                // let newGoal = {
                //   goalTitle: window.prompt(
                //     "First things first, let's give your goal a descriptive title:",
                //   ),
                //   goalMetric: window.prompt('What will you be measuring?'),
                //   goalProgress: window.prompt(
                //     'How much progress have you made? 0-100%',
                //   ),
                // }
                // if (
                //   !newGoal.goalTitle ||
                //   !newGoal.goalMetric ||
                //   !newGoal.goalProgress
                // )
                //   return
                // setNewGoal(newGoal)
                setOpenGoalForm(true)
              }}
            >
              +
            </button>
          </div>

          <Collapse
            accordion
            items={renderGoalItems()}
            style={{
              background: '#313233',
            }}
            onChange={(key) => {
              console.log(key)
              setActiveGoal(props.goals[key])
            }}
          />
          <CreateGoalForm
            open={openGoalForm}
            onCreate={onCreate}
            onCancel={() => {
              setOpenGoalForm(false)
            }}
          />
          <CreateMilestoneForm
            open={openMilestoneForm}
            onCreate={onCreateMilestone}
            goal={activeGoal?.description}
            onCancel={() => {
              setOpenMilestoneForm(false)
            }}
            collaborators={props.users}
          />
          <UpdateMilestoneForm
            open={openTasksForm}
            // onCreate={onCreateTasks}
            onCreate={onUpdateMilestone}
            goal={props.goals?.find((_goal) => {
              return _goal === activeGoal
            })}
            milestone={activeMilestone}
            onCancel={() => {
              setActiveMilestone(null)
              setOldActiveIndex(null)
              setOpenTasksForm(false)
            }}
            tasks={activeMilestone?.tasks}
            deleteMilestone={(milestone) => {
              onDeleteMilestone(milestone)
              setOpenTasksForm(false)
              setActiveMilestone(null)
            }}
            collaborators={props.users}
            handleTaskChange={async (task, done, index, milestone) => {
              let newGoals = props.goals
              let goalIndex = newGoals?.findIndex((_goal) => {
                return _goal.milestones.includes(milestone)
              })

              if (goalIndex == -1) return

              let milestoneIndex = newGoals[goalIndex].milestones.findIndex(
                (milestone) =>
                  milestone.description == activeMilestone.description,
              )

              if (milestoneIndex == -1) return

              let taskIndex = newGoals[goalIndex].milestones[
                milestoneIndex
              ].tasks.findIndex(
                (_task) => _task.description == task.description,
              )

              if (taskIndex == -1) return

              newGoals[goalIndex].milestones[milestoneIndex].tasks[
                taskIndex
              ].open = !done

              await updateGoals(newGoals)

              setOldActiveIndex([goalIndex, milestoneIndex])

              setActiveMilestone(null)
              setOpenTasksForm(false)
            }}
          />
          <UpdateGoalForm
            open={goalToEdit}
            onCreate={updateGoalDescription}
            onCancel={() => {
              setGoalToEdit(null)
            }}
            handleDelete={deleteGoal}
            goal={goalToEdit}
            collaborators={props.users}
          />
        </DesktopGoals.Wrapper>
      )}
    </Styles.Wrapper>
  )
}

const Styles = {
  Wrapper: styled.div`
    // width: 50%;
    flex-direction: column;

    .ant-collapse-expand-icon {
      color: #fff;
    }

    .ant-collapse-header-text {
      color: #fff;
    }
  `,
}

const DesktopGoals = {
  Wrapper: styled.div`
    padding: 1rem 3rem;

    .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      button {
        margin-left: 1rem;
        border-radius: 50%;
        border: none;
        text-align: center;
      }
    }

    .Table {
      width: 100%;
    }
  `,
}

const MobileGoals = {
  Wrapper: styled(DesktopGoals.Wrapper)`
    position: fixed;
    width: 100vw;
    bottom: 0;

    justify-content: center;
  `,
}

export default Goals
